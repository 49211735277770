import React, {  } from 'react'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DaySelector } from './DaySelector';
import { TimeInformation } from './TimeInformation';
import { TimeRangeSlider } from './TimeRangeSlider';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { usePeriodicitieSchedule } from './usePeriodicitieSchedule';


export const Periodicitie = ({
    setSchedule,
    schedule
}) => {
    const {
        day,
        currentRange,
        available,
        handleScheduleChecked,
        handleScheduleRange,
        setDay,
        statuses
    } = usePeriodicitieSchedule({
        setSchedule,
        schedule
    })
    return <Grid container spacing={3}>
        <Grid item xs={12}>
            <Stack spacing={2}>
                <Typography component="span" variant="body1" color="text.secondary">
                    Выберите дни по которым Вы сможете выходить на смены
                </Typography>
                <Box display={'flex'} justifyContent={'center'}>
                    <DaySelector
                        statuses={statuses}
                        value={day}
                        onChange={setDay}
                    />
                </Box>
            </Stack>
        </Grid>

        <Grid item xs={12}>
            <Stack spacing={1}>

                <FormControlLabel control={<Switch
                    checked={available}
                    onChange={(event) => {
                        handleScheduleChecked(event.target.checked);
                    }}
                />} label="Могу выйти на смену" />

                <Typography component="span" variant="body1" color="text.secondary">
                    Выберите время в которые Вы сможете выходить на смены
                </Typography>

                <Box sx={{ pl: 3, pr: 3 }}>
                    <TimeRangeSlider
                        disabled={!available}
                        day={day}
                        value={currentRange}
                        onChange={handleScheduleRange}
                    />
                </Box>

            </Stack>
        </Grid>

        <Grid item xs={12}>
            <TimeInformation
                currentDay={day}
                currentRange={currentRange}
            />
        </Grid>
    </Grid>
}
