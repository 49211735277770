import { useMutation } from "@tanstack/react-query";
import apiClient, {queryClient} from "./http-common";
import React, { useState } from 'react';
import { errorReports } from "./errorReports";

export const useSendComment = ({ onSuccess = () => { }, onError = () => { } } = {}) => {
    const [comment, setComment] = useState(null)
    return useMutation({
        mutationKey: "rest-comments",
        mutationFn: async ({
            rest_id,
            comment
        }) => {
            setComment({
                rest_id,
                comment
            })
            return await apiClient.post("rest-comments/create", {
                rest_id,
                comment
            });
        },
        onSuccess: (res) => {
            queryClient.invalidateQueries({ queryKey: ['rest-comments/index'] })
            onSuccess(comment, res)
        },
        onError: (err) => {
            onError(comment, err)
            console.log('rest-comments/create err', err)
            errorReports(err)
        },
    })
}