import React, { } from 'react'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { isAvailable } from './utils';
import { TimeInformation } from './TimeInformation';
import { DateSelector } from './DateSelector';
import { TimeRangeSlider } from './TimeRangeSlider';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useParticularsSchedule } from './useParticularsSchedule';

export const Particulars = ({
    dates,
    setDates,
    schedule
}) => {
    const {
        currentDay,
        currentRange,
        date,
        handleDatesChecked,
        handleDatesRange,
        setDate
    } = useParticularsSchedule({
        dates,
        schedule,
        setDates
    })


    return <Grid container spacing={3}>
        <Grid item xs={12}>
            <Typography component="span" variant="body1" color="text.secondary">
                Выберите даты в которые Вы сможете выходить на смены
            </Typography>
            <DateSelector
                onChange={setDate}
                value={date}
                workDates={dates}
                workSchedule={schedule}
            />
        </Grid>
        <Grid item xs={12}>
            <Stack spacing={1}>

                <FormControlLabel control={<Switch
                    checked={isAvailable(dates, date, schedule)}
                    onChange={(event) => {
                        handleDatesChecked(event.target.checked);
                    }}
                />} label="Могу выйти на смену" />

                <Typography component="span" variant="body1" color="text.secondary">
                    Выберите время в которые Вы сможете выхойти на смену
                </Typography>

                <Box sx={{ pl: 3, pr: 3 }}>
                    <TimeRangeSlider
                        disabled={!isAvailable(dates, date, schedule)}
                        day={currentDay}
                        value={currentRange}
                        onChange={handleDatesRange}
                    />
                </Box>

            </Stack>
        </Grid>

        <Grid item xs={12}>
            <TimeInformation
                currentDay={currentDay}
                currentRange={currentRange}
            />
        </Grid>
    </Grid>
}