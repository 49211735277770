import React, { } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import { RequestErrorMessage } from '../RequestErrorMessage/RequestErrorMessage';

export const CommentForm = ({
    isPending,
    onSubmit,
    error,
    reset
}) => {

    return <Box
        component={'form'}
        action="#"
        method="POST"
        onSubmit={onSubmit}
        sx={{ width: '100%', bgcolor: 'background.paper' }}
    >

        <Grid container spacing={3}>

            <Grid item xs={12}>
                <TextField
                    label="Комментарий"
                    name="comment"
                    id="comment"
                    fullWidth
                    multiline
                // defaultValue={value?.last_name}
                />
            </Grid>

            {error && <Grid item xs={12}>
                <RequestErrorMessage
                    error={error}
                    reset={reset}
                />
            </Grid>}


            <Grid item xs={12}>

                <LoadingButton
                    loading={isPending}
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={false}
                >Отправить</LoadingButton>

            </Grid>

        </Grid>

    </Box>

}