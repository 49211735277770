import React, { useEffect, useRef, useState } from 'react'
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { useAsyncValue } from 'react-router-dom'
import LoadingButton from '@mui/lab/LoadingButton';
import { useUpdateUser } from '../../api/useUpdateUser';
import { RequestErrorMessage } from '../../ui/RequestErrorMessage/RequestErrorMessage';
import { useSnackbar } from 'notistack';
import { useSetTitle } from '../../utils/useTitle';
import { AvatarInputs } from '../../components/ProfileForm/AvatarInputs';
import { NameInputs } from '../../components/ProfileForm/NameInputs';
import { СontactsInputs } from '../../components/ProfileForm/СontactsInputs';
import { BirthdateInputs } from '../../components/ProfileForm/BirthdateInputs';
import { LocationInputs } from '../../components/ProfileForm/LocationInputs';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper'

export const UserSetting = () => {
    useSetTitle('Настройки профиля')
    const [place, setPlace] = useState([-16.460174, -133.395840])
    const ymap = useRef(null)
    const { enqueueSnackbar } = useSnackbar();
    const data = useAsyncValue();
    // const data = useLoaderData();
    const value = data?.data

    const { mutate, isPending, error, reset } = useUpdateUser({
        onSuccess: () => {
            enqueueSnackbar('Настройки сохранены', { variant: 'success' })
        }
    })

    useEffect(() => {
        setPlace([value?.longitude, value?.latitude])
    }, [value?.longitude, value?.latitude])

    // console.log('place', place)
    return <Container
        component={'form'}
        action="#"
        method="POST"
        sx={{ /*width: '100%', bgcolor: 'background.paper',*/ marginBottom: '24px' }}
        onSubmit={(e) => {
            e.preventDefault()
            const data = new FormData(e.currentTarget);
            const v = {
                ...[...data.entries()].reduce((p, [k, v]) => ({ ...p, [k]: v }), {}),
                // avatar: fileResult?.file_id || value?.avatar?.id,
                // birth_date: formateDateToServer(birthdate) || value?.birth_date
            }
            // console.log('data', [...data.entries()], value)
            mutate(v)
        }}
    >
        <Paper>
            <Container>
                <Grid container spacing={3}>

                    <AvatarInputs value={value} />

                    <NameInputs value={value} />

                    <СontactsInputs value={value} />

                    <BirthdateInputs value={value} />

                    <Grid item xs={12}>
                        <TextField
                            label="Рост"
                            type='number'
                            name="height"
                            id="height"
                            fullWidth
                            defaultValue={value?.height}
                        />
                    </Grid>

                    <LocationInputs value={value} />

                    {error && <Grid item xs={12}>
                        <RequestErrorMessage
                            error={error}
                            reset={reset}
                        />
                    </Grid>}

                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <LoadingButton
                            loading={isPending}
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={false} >Сохранить</LoadingButton>
                    </Grid>
                    <Grid item xs={12}></Grid>
                </Grid>

            </Container>
        </Paper>
    </Container>
}