import { QueryClientProvider } from "@tanstack/react-query";
import {
  createBrowserRouter,
  RouterProvider,
  redirect,
  useLoaderData,
  Await,
  defer,
  useAsyncError,
  useNavigate
} from "react-router-dom";
import { Suspense, useEffect, useMemo, useState } from 'react'
import { Login } from './pages/Login/Login'
import { Layout } from './components/Layout/Layout'
import { ActiveWorkshifts } from './pages/ActiveWorkshifts/ActiveWorkshifts'

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ru';
import { WorkshiftView } from './pages/WorkshiftView/WorkshiftView'
import { Settings } from './pages/Settings/Settings'
import { UserSetting } from './pages/UserSetting/UserSetting'
import { ModalProvider } from './utils/useModal'
import { SingUp } from './pages/SingUp/SingUp'
import { Forgot } from './pages/Forgot/Forgot'
import { AllWorkshifts } from './pages/AllWorkshifts/AllWorkshifts'
import { ChangePasswordSetting } from './pages/ChangePasswordSetting/ChangePasswordSetting'
import apiClient, { queryClient } from './api/http-common'
import { YMaps } from '@pbe/react-yandex-maps';
import Typography from '@mui/material/Typography';
import { VARIABLES } from "./utils/variables";
import { SnackbarProvider } from 'notistack';
import { TitleProvider, useSetTitle } from './utils/useTitle'
import { Welcome } from "./pages/Welcome/Welcome";
import { useTheme } from "./utils/useTheme";
import { InternshipGrade } from "./pages/InternshipGrade/InternshipGrade";
import { WaitResultWorkshifts } from "./pages/WaitResultWorkshifts/WaitResultWorkshifts";
import { ListPlaceholder } from "./ui/ListPlaceholder/ListPlaceholder";
import { Notifications } from "./pages/Notifications/Notifications";
import { Preloader } from "./ui/Preloader/Preloader";
import { Schedule } from "./pages/Schedule/Schedule";
import { PersonalFiles } from "./pages/PersonalFiles/PersonalFiles";



async function loginLoader() {
  const authData = JSON.parse(localStorage.getItem("authData"));
  if (authData) {
    return redirect("/");
  }
  return null;
}
function protectedLoader({ request }) {
  const authData = JSON.parse(localStorage.getItem("authData"));
  if (!authData) {
    let params = new URLSearchParams();
    params.set("from", new URL(request.url).pathname);
    return redirect("/login?" + params.toString());
  } else if (authData.user.status === 9) {
    return redirect("/welcome");
  }
  return null;
}

const ErrorComponent = () => {
  useSetTitle('Ошибка')
  const error = useAsyncError();
  const navigate = useNavigate()
  useEffect(() => {
    if (error?.response?.status === 406 && error?.config.url.includes('auth/refresh-token')) {
      navigate('/login')
    }
  }, [error])
  console.log('error', error)
  return <ListPlaceholder text={'Смены ещё недоступны'} />//<Typography>Смены ещё недоступны</Typography>
}
const FallbackComponent = () => {
  useSetTitle('Загрузка')
  return <Preloader />
}

const SuspenseFallbackCreator = (Component) => (props) => {
  /* eslint-disable */
  const data = useLoaderData();
  return <Suspense
    fallback={<FallbackComponent />}
  >
    <Await
      resolve={data.data}
      errorElement={<ErrorComponent />}
    >
      {() => (
        <Component {...props} />
      )}
    </Await>
  </Suspense>
}

const router = createBrowserRouter([
  {
    path: "/",
    loader: protectedLoader,
    Component: (props) => <ModalProvider><Layout {...props} /></ModalProvider>,
    children: [
      {
        path: 'workshift/:id',
        loader: ({ params }) => {
          try {
            const data = queryClient.fetchQuery({
              staleTime: 200000,
              queryKey: [`workshift/view`, { id: params.id }],
              queryFn: async () => {
                return await apiClient.get(`workshift/view`, {
                  params
                });

              },
            })
            return defer({ data });
          } catch (error) {
            console.log(error)
            return null
          }
        },
        Component: SuspenseFallbackCreator(WorkshiftView)
      },
      {
        path: 'internship-grade/:id',
        loader: ({ params }) => {
          try {
            const data = queryClient.fetchQuery({
              staleTime: 200000,
              queryKey: [`workshift/view`, { id: params.id }],
              queryFn: async () => {
                return await apiClient.get(`workshift/view`, {
                  params
                });

              },
            })
            return defer({ data });
          } catch (error) {
            console.log(error)
            return null
          }
        },
        Component: SuspenseFallbackCreator(InternshipGrade)
      },

      {
        path: '/settings',
        Component: Settings
      },
      {
        path: '/settings/password',
        Component: ChangePasswordSetting
      },
      {
        path: '/settings/user',
        loader: (request) => {
          try {
            // const { user } = JSON.parse(localStorage.getItem("authData"));
            const data = queryClient.fetchQuery({
              staleTime: 200000,
              queryKey: [`user/view-me`],
              queryFn: async () => {
                return await apiClient.get(`user/view-me`);

              },
            })
            return defer({ data });
            // return data
          } catch (error) {
            console.log(error)
            return null
          }
        },
        Component: SuspenseFallbackCreator(UserSetting)
      },

      {
        // path: "protected",
        index: true,
        hydrateFallbackElement: <FallbackComponent />,
        loader: (request) => {
          try {
            const data = queryClient.fetchQuery({
              staleTime: 200000,
              queryKey: ['workshift/active'],
              queryFn: async () => {
                return await apiClient.get(`workshift/active`, {
                  params: {
                    // page: query.get("page")
                  }
                });

              },
            })
            return defer({ data });
            // return data
          } catch (error) {
            console.log(error)
            return null
          }
        },
        // loader: protectedLoader,
        Component: SuspenseFallbackCreator(ActiveWorkshifts),
      },
      {
        path: "/wait-result",
        // index: true,
        hydrateFallbackElement: <FallbackComponent />,
        loader: (request) => {
          try {
            const data = queryClient.fetchQuery({
              staleTime: 200000,
              queryKey: ['workshift/wait-result'],
              queryFn: async () => {
                return await apiClient.get(`workshift/wait-result`, {
                  params: {
                    // page: query.get("page")
                  }
                });

              },
            })
            return defer({ data });
            // return data
          } catch (error) {
            console.log(error)
            return null
          }
        },
        // loader: protectedLoader,
        Component: SuspenseFallbackCreator(WaitResultWorkshifts),
      },
      {
        path: "/all",
        // index: true,
        loader: (request) => {
          try {
            const data = queryClient.fetchQuery({
              staleTime: 200000,
              queryKey: ['workshift/index'],
              queryFn: async () => {
                return await apiClient.get(`workshift/index`, { params: {} });

              },
            })
            return defer({ data });
            // return data
          } catch (error) {
            console.log(error)
            return null
          }
        },
        Component: SuspenseFallbackCreator(AllWorkshifts),
      },
      {
        path: "/schedule",
        // index: true,
        hydrateFallbackElement: <FallbackComponent />,
        loader: (request) => {
          try {
            const data = queryClient.fetchQuery({
              staleTime: 200000,
              queryKey: ['users-schedule/index'],
              queryFn: async () => {
                return await apiClient.get(`users-schedule/index`, {
                  params: {
                    // page: query.get("page")
                  }
                });

              },
            })
            return defer({ data });
          } catch (error) {
            console.log(error)
            return null
          }
        },
        // loader: protectedLoader,
        Component: SuspenseFallbackCreator(Schedule),
      },
      {
        path: "/personal-files",
        // index: true,
        hydrateFallbackElement: <FallbackComponent />,
        loader: (request) => {
          try {
            const data = queryClient.fetchQuery({
              staleTime: 200000,
              queryKey: ['users-personal-files/index'],
              queryFn: async () => {
                return await apiClient.get(`users-personal-files/index`, {
                  params: {
                    // page: query.get("page")
                  }
                });

              },
            })
            return defer({ data });
          } catch (error) {
            console.log(error)
            return null
          }
        },
        // loader: protectedLoader,
        Component: SuspenseFallbackCreator(PersonalFiles),
      },
      {
        path: "/information-files",
        // index: true,
        hydrateFallbackElement: <FallbackComponent />,
        loader: (request) => {
          try {
            const data = queryClient.fetchQuery({
              staleTime: 200000,
              queryKey: ['information-files/index'],
              queryFn: async () => {
                return await apiClient.get(`information-files/index`, {
                  params: {
                    // page: query.get("page")
                  }
                });

              },
            })
            return defer({ data });
          } catch (error) {
            console.log(error)
            return null
          }
        },
        // loader: protectedLoader,
        Component: SuspenseFallbackCreator(PersonalFiles),
      },
      {
        path: "/notifications",
        // index: true,
        hydrateFallbackElement: <FallbackComponent />,
        loader: (request) => {
          try {
            const data = queryClient.fetchQuery({
              staleTime: 200000,
              queryKey: ['workshift/active'],
              queryFn: async () => {
                return await apiClient.get(`workshift/active`, {
                  params: {
                    // page: query.get("page")
                  }
                });

              },
            })
            return defer({ data });
            // return data
          } catch (error) {
            console.log(error)
            return null
          }
        },
        // loader: protectedLoader,
        Component: SuspenseFallbackCreator(Notifications),
      },
    ],
  },

  {
    path: "/welcome",
    loader: (request) => {
      const authData = JSON.parse(localStorage.getItem("authData"));
      if (!authData) {
        let params = new URLSearchParams();
        params.set("from", new URL(request.url).pathname);
        return redirect("/login?" + params.toString());
      } else if (authData.user.status !== 9) {
        return redirect("/");
      }
      try {
        // const { user } = JSON.parse(localStorage.getItem("authData"));
        const data = queryClient.fetchQuery({
          staleTime: 200000,
          queryKey: [`user/view-me`],
          queryFn: async () => {
            return await apiClient.get(`user/view-me`);

          },
        })
        return defer({ data });
        // return data
      } catch (error) {
        console.log(error)
        return null
      }
    },
    Component: SuspenseFallbackCreator(Welcome),
  },
  {
    path: "/login",
    loader: loginLoader,
    Component: Login,
    // element: <LoginPage />,
  },
  {
    path: "/singup",
    loader: loginLoader,
    Component: SingUp
  },
  {
    path: "/forgot",
    loader: loginLoader,
    Component: Forgot
  }
], { basename: VARIABLES.BASE_URL });


function App() {
  const theme = useTheme()


  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <TitleProvider>
            <SnackbarProvider maxSnack={3} autoHideDuration={1500} anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }} >
              <YMaps
                query={{
                  apikey: VARIABLES.YANDEX_MAP_APIKEY,
                  suggest_apikey: VARIABLES.YANDEX_MAP_SUGGEST_APIKEY
                }}
              >
                <RouterProvider
                  router={router}
                // fallbackElement={<Preloader />} 
                />
              </YMaps>
            </SnackbarProvider>
          </TitleProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </QueryClientProvider >

  );
}

export default App;
