import React, { useRef, useState } from 'react'
import { ItemsList } from '../../ui/ItemsList/ItemsList';
import { ResultsSummary } from '../ResultsSummary/ResultsSummary';
import { ResultItem } from '../ResultItem/ResultItem';

export const ResultsList = ({ ws_id, values, commission, type, base_commission }) => {
    const isEmpty = values.some(v => v.is_empty)
    return <>
        <ItemsList
            emptyText={'Отчеты ещё не отправлены'}
            isFetching={false}
            values={values}
            elementsMapper={value => <ResultItem key={value.id} {...value} ws_type={type} />}
        />
        {!!values.length && !isEmpty && <ResultsSummary commission={commission} base_commission={base_commission} values={values} />}
    </>
}
