import React, { useRef, useState } from 'react'
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import STATUSES, { isAllowDeleteReport } from '../../utils/STATUSES';
import { PRODUCTS } from '../../utils/variables';
import { DeleteReportAction } from '../DeleteReportAction/DeleteReportAction';




export const ResultItem = ({
    id,
    discounted_amount,
    volume,
    product,
    file,
    amount,
    comment,
    reject_reason,
    is_empty,
    status,
    ws_type
}) => {

    const isEmptyInternship = (ws_type === 1 && is_empty)
    return <>
        <ListItem
            dense={!isEmptyInternship}
            disablePadding={!isEmptyInternship}
            secondaryAction={
                isAllowDeleteReport(status) && <DeleteReportAction id={id} />
            }
        >
            < ListItemAvatar >
                <Tooltip disableFocusListener placement='top' title={STATUSES.REPORT_TEXT[status]}>
                    <IconButton edge="end" aria-label="help">
                        {STATUSES.REPORT_ICON[status]}
                    </IconButton>
                </Tooltip>
            </ListItemAvatar >
            <ListItemText
                primary={is_empty ? (ws_type === 1 ? 'Стажировка без продаж' : 'Без продаж') : (volume + ' мл - ' + PRODUCTS[product])}
                secondary={
                    !is_empty ?
                        <Box>
                            <Typography component="span" fontSize={'inherit'} variant="body1" color="text.secondary">
                                Сумма за напиток:&nbsp;
                            </Typography>
                            <Typography component="span" fontSize={'inherit'} variant="body1" color="text.primary">
                                {amount}
                            </Typography>
                        </Box> :
                        <>
                            {comment && <Box>
                                <Typography component="span" fontSize={'inherit'} variant="body1" color="text.secondary">
                                    Комментарий:&nbsp;
                                </Typography>
                                <Typography component="span" fontSize={'inherit'} variant="body1" color="text.primary">
                                    {comment}
                                </Typography>
                            </Box>}
                            {reject_reason && status === 2 && <Box>
                                <Typography component="span" fontSize={'inherit'} variant="body1" color="text.secondary">
                                    Причина:&nbsp;
                                </Typography>
                                <Typography component="span" fontSize={'inherit'} variant="body1" color="text.primary">
                                    {reject_reason}
                                </Typography>
                            </Box>}
                        </>
                }
            />
        </ListItem >
        <Divider />
    </>
}