import React, { useState } from 'react'
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import {
    useAsyncValue
} from "react-router-dom";
import { useSetTitle } from '../../utils/useTitle';
import { ListPlaceholder } from '../../ui/ListPlaceholder/ListPlaceholder';
import { FileItem } from '../../ui/FileItem/FileItem';




export const PersonalFiles = () => {
    useSetTitle('Мои документы')
    const { data } = useAsyncValue();


    return data.values.length === 0 ? <ListPlaceholder text={'Файлов нет'} /> : <List>
        {data.values.map(({ file }) => (<FileItem {...file} />))}
    </List>
}