import React, { } from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { formateDateTime } from '../../utils/utils';
import Divider from '@mui/material/Divider';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { VARIABLES } from '../../utils/variables';
import Tooltip from '@mui/material/Tooltip';

export const Comment = ({
    value
}) => {
    const { comment, user, create_at, approved } = value
    return <>
        <ListItem
            alignItems="flex-start"
            secondaryAction={
                !approved && <Tooltip disableFocusListener placement='top' title={'Этот комментарий видите только Вы и менеджер'}>
                    <VisibilityOffIcon color='disabled' size={24} />
                </Tooltip>
            }
        >
            <ListItemAvatar>
                <Avatar alt={user.displayName} src={VARIABLES.FILES_URL + `${user.avatar?.file}`} />
            </ListItemAvatar>
            <ListItemText
                primary={user.displayName}
                secondary={
                    <React.Fragment>
                        <Typography
                            variant="body2"
                            color="text.primary"
                        >
                            {comment}
                        </Typography>
                        <Typography
                            variant="body2"
                            fontSize={12}
                        >
                            {formateDateTime(create_at)}
                        </Typography>
                    </React.Fragment>
                }
            />
        </ListItem>
        <Divider />
    </>

}