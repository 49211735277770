

export const calculateCommission = (values, commission, base_commission) => {
    const { amount, volume, discounted_amount } = values.reduce((acc, cur) => ({
        amount: acc.amount + cur.amount,
        volume: acc.volume + cur.volume,
        discounted_amount: acc.discounted_amount + cur.discounted_amount,
    }), { amount: 0, volume: 0, discounted_amount: 0 })


    const totalCommission = amount * (commission / 100)
    const isMinimalCommission = base_commission > totalCommission
    const finallyCommission = isMinimalCommission ? base_commission : totalCommission
    return {
        totalCommission,
        isMinimalCommission,
        finallyCommission,
        amount,
        volume,
        discounted_amount
    }
}