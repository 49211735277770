import Select from '@mui/material/Select';
import { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { useCitiesList } from "../../api/useCitiesList";
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';

export const CitySelect = ({ anyVariant = false, onChange = () => { }, value, defaultValue, ...props }) => {
    const [open, setOpen] = useState(false);
    const [internal, setInternal] = useState(value || defaultValue || '');
    const { isFetching, values, refetch } = useCitiesList()

    const anyCity = anyVariant ? [{
        id: 0,
        name: 'Все',
        country: 'WORLD',
        longitude: -16.460174,
        latitude: -133.395840
    }] : []
    const variants = [...anyCity, ...values]

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
        refetch()
    };

    return <Select
        {...props}
        value={internal}
        onChange={(e, c) => {
            setInternal(e.target.value)
            onChange(variants.find(v => e.target.value === v.id))
        }}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
    >
        {isFetching && !values.length ?
            <Box sx={{ margin: 'auto', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                <CircularProgress color="inherit" />
            </Box> :
            [...anyCity, ...values].map(({ id, name }) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
    </Select>
}