import React, { useRef, useState } from 'react'
import { FileUploader } from '../FileUploader/FileUploader';
import { useSnackbar } from 'notistack';
import { useAddWorkshiftFile } from '../../api/useAddWorkshiftFile';

export const CommissionReportForm = ({ ws_id }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { mutateAsync, isPending, error, reset } = useAddWorkshiftFile(ws_id, {
        onSuccess: () => {
            enqueueSnackbar('Отчет отправлен', { variant: 'success' })
        },
        onError: () => {
            enqueueSnackbar('Ошибка при отправке. Попробуйте ещё раз', { variant: 'error' })
        }
    })
    return <FileUploader
        loading={isPending}
        text='Чек комиссии'
        onSuccess={(result) => {
            console.log(result)
            mutateAsync({
                file_id: result.data.file.id,
                type: 'commission'
            })
        }}
    // error={empty['file_id']} 
    />
}