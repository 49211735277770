import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
    Link,
    useAsyncValue,
} from "react-router-dom";
import { formateDateTime } from '../../utils/utils';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import STATUSES, { isAllowComleteStatus, isAllowShowResultsStatus, isAllowStartStatus, isNeedAcceptStatus } from '../../utils/STATUSES';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { YandexMap } from '../../components/YandexMap/YandexMap';
import { VARIABLES } from '../../utils/variables';
import { useSnackbar } from 'notistack';
import { useSetTitle } from '../../utils/useTitle';
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip';
import CopyAllIcon from '@mui/icons-material/CopyAll'
import { ItemsList } from '../../ui/ItemsList/ItemsList';
import { useCopyToClipboard } from '@uidotdev/usehooks';
import IconButton from '@mui/material/IconButton';
import MuiLink from '@mui/material/Link'
import Stack from '@mui/material/Stack';
import Rating from '@mui/material/Rating';
import Badge from '@mui/material/Badge';
import SchoolIcon from '@mui/icons-material/School';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import { AcceptButtonWorkshift } from '../../components/AcceptButtonWorkshift/AcceptButtonWorkshift';
import { StartWorkButtonWorkshift } from '../../components/StartWorkButtonWorkshift/StartWorkButtonWorkshift';
import { CompleteButtonWorkshift } from '../../components/CompleteButtonWorkshift/CompleteButtonWorkshift';
import { InternItem } from '../../components/InternItem/InternItem';
import { CommentsList } from '../../components/CommentsList/CommentsList';
import { ResultsComponent } from '../../components/ResultsComponent/ResultsComponent';
import { RulesList } from '../../components/RulesList/RulesList';
import LoadingButton from '@mui/lab/LoadingButton';
import { useCompleteInternship } from '../../api/useCompleteInternship';


const Image = styled('img')({
    display: 'block',
    width: '100%',
    maxWidth: '100%',
    height: 'auto',
    borderRadius: '4px'
});

const YandexMapLink = ({ yandex_oid, latitude, longitude, address }) => {
    const { enqueueSnackbar } = useSnackbar()
    const [copiedText, copyToClipboard] = useCopyToClipboard();

    let link = null
    if (yandex_oid) {
        link = `https://yandex.ru/maps/org/${yandex_oid}`
    } else if (latitude && longitude) {
        link = `https://yandex.ru/maps/?pt=${latitude},${longitude}&z=12&l=map`
    }

    const onClick = () => {
        copyToClipboard(address)
        enqueueSnackbar({
            variant: 'info',
            message: 'Адрес скопирован'
        })
    }

    return link ?
        <>
            <MuiLink href={link}>{address}</MuiLink>
            <Tooltip disableFocusListener placement='top' title={'Копировать адрес'}>
                <IconButton size='small' sx={{ marginLeft: '12px' }} edge="end" aria-label="help" onClick={onClick}>
                    <CopyAllIcon fontSize='inherit' />
                </IconButton>
            </Tooltip>
        </> :
        <MuiLink onClick={onClick}>{address}</MuiLink>

}


const CompleteInternship = ({ ws_id }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { mutate, isPending } = useCompleteInternship(ws_id, {
        onSuccess: () => {
            enqueueSnackbar('Стажировка окончена', { variant: 'success' })
        }
    })
    return <LoadingButton
        onClick={mutate}
        fullWidth
        variant='contained'
        loading={isPending}
    >{'Завершить стажировку'}</LoadingButton>
}

export const WorkshiftView = () => {
    // const data = useLoaderData();
    const data = useAsyncValue();

    const [tab, setTab] = useState('rules');



    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    useSetTitle(data?.data?.restaurant.name ? `Смена в ${data?.data?.restaurant.name}` : 'Смена')

    if (!data) {
        return <Box sx={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            Не найдено
        </Box>
    }

    const { restaurant,
        start,
        end,
        description,
        state,
        id,
        no_sales,
        type,
        parent,
        base_commission,
        commission,
        workshifts,
        workshiftResults = [],
        workshiftFiles = [],
        crossWorkshifts } = data?.data || {}

    let needResults = state === 6 ? '. ' + [
        workshiftResults.length === 0 && 'Результаты смены',
        workshiftFiles.filter(v => v.type === 'commission').length === 0 && 'Чек из банка',
        workshiftFiles.filter(v => v.type === 'sales_receipt').length === 0 && 'Чек из заведения',
    ].filter(Boolean).join(', ') : ''

    const internshipGradesComplete = workshifts ? workshifts.every(({ internshipGrades }) => Boolean(internshipGrades)) : false

    const isAllowShowInternshipComplete = type === 1 && no_sales && state === 6 && internshipGradesComplete

    needResults = type === 1 && no_sales ? (!internshipGradesComplete ? '. Оценка стажера' : '') : needResults

    return <Grid container sx={{ pl: 1, pr: 1 }} spacing={3}>
        <Grid item xs={12}>
            <Paper>
                <Alert severity={STATUSES.SEVERITY[state]} icon={STATUSES.ICON[state]}>{STATUSES.TEXT[state]}{needResults}</Alert>
            </Paper>
        </Grid>

        {isAllowShowInternshipComplete && <Grid item xs={12}>
            <Paper>
                <Container>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <CompleteInternship ws_id={id} />
                        </Grid>

                        <Grid item xs={12}></Grid>

                    </Grid>
                </Container>
            </Paper>
        </Grid>}

        {isAllowShowResultsStatus(state) && !no_sales && <Grid item xs={12}>
            <Paper>
                <Container>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <ResultsComponent
                                workshiftResults={workshiftResults}
                                workshiftFiles={workshiftFiles}
                                type={type}
                                ws_id={id}
                                no_sales={no_sales}
                                rest_id={restaurant.id}
                                commission={commission}
                                base_commission={base_commission}
                                state={state} />
                        </Grid>

                        <Grid item xs={12}></Grid>

                    </Grid>
                </Container>
            </Paper>
        </Grid>}



        <Grid item xs={12}>
            <Paper>
                <Container>

                    <Grid container spacing={2}>

                        {restaurant.avatar && <Grid item xs={12} sm={4} md={3} >

                            <Image
                                src={VARIABLES.FILES_URL + `${restaurant.avatar?.file}`}
                                title={restaurant.name}
                                alt={restaurant.name}
                            />

                        </Grid>}

                        <Grid item xs={12} sm={8} md={9}>
                            <Stack spacing={1} direction={'column'}>


                                <Typography variant="h5" component="div">
                                    {restaurant.name}
                                </Typography>

                                <Box>
                                    <Typography component="span" variant="body1" color="text.secondary">
                                        Начало:&nbsp;
                                    </Typography>
                                    <Typography component="span" variant="body1" color="text.primary">
                                        {formateDateTime(start)}
                                    </Typography>
                                </Box>

                                <Box>
                                    <Typography component="span" variant="body1" color="text.secondary">
                                        Завершение:&nbsp;
                                    </Typography>
                                    <Typography component="span" variant="body1" color="text.primary">
                                        {formateDateTime(end)}
                                    </Typography>
                                </Box>

                                {!!description && <Box>
                                    <Typography component="span" variant="body1" color="text.primary">
                                        {description}
                                    </Typography>
                                </Box>}

                                <Stack spacing={1} direction={'row'}>
                                    {type !== 0 && <Chip
                                        color='info'
                                        variant='outlined'
                                        icon={<SchoolIcon />}
                                        label="Стажировка" />}

                                    {no_sales && <Chip
                                        color='info'
                                        variant='outlined'
                                        icon={<MoneyOffIcon />}
                                        label="Без продаж" />}

                                </Stack>
                            </Stack>
                        </Grid>



                        {isNeedAcceptStatus(state) && <>
                            <Grid item xs={12}><Divider /></Grid>
                            <Grid item xs={12}>
                                <AcceptButtonWorkshift ws_id={id} />
                            </Grid>
                        </>}
                        {isAllowStartStatus(state) && <>
                            <Grid item xs={12}><Divider /></Grid>
                            <Grid item xs={12}>
                                <StartWorkButtonWorkshift ws_id={id} />
                            </Grid>
                        </>}
                        {isAllowComleteStatus(state) && <>
                            <Grid item xs={12}><Divider /></Grid>
                            <Grid item xs={12}>
                                <CompleteButtonWorkshift ws_id={id} />
                            </Grid>
                        </>}


                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography component="span" variant="body1" color="text.secondary">
                                Адрес:&nbsp;
                            </Typography>
                            <Typography component="span" variant="body1" color="text.primary">
                                <YandexMapLink {...restaurant} />
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography component="span" variant="body1" color="text.secondary">
                                Комиссия:&nbsp;
                            </Typography>
                            <Typography component="span" variant="body1" color="text.primary">
                                {commission}%
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography component="span" variant="body1" color="text.secondary">
                                Минимальная комиссия:&nbsp;
                            </Typography>
                            <Typography component="span" variant="body1" color="text.primary">
                                {base_commission}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="body2" color="text.secondary">
                                {restaurant.description}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="body2" color="text.secondary">
                                {restaurant.comment}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}></Grid>

                    </Grid>


                </Container>
            </Paper>
        </Grid>

        {type === 0 && !!crossWorkshifts?.length && <Grid item xs={12}>
            <Paper>
                <Container>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <Typography variant="h5" component="div">
                                Коллеги
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <ItemsList
                                emptyText={'Стажеров нет'}
                                // isFetching={isFetching}
                                values={crossWorkshifts}
                                elementsMapper={value => <InternItem key={value.id} {...value} />}
                            />
                        </Grid>
                        <Grid item xs={12}></Grid>

                    </Grid>
                </Container>
            </Paper>
        </Grid>}

        {type === 2 && <Grid item xs={12}>
            <Paper>
                <Container>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <Typography variant="h5" component="div">
                                Наставник
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <ItemsList
                                // emptyText={'Стажеров нет'}
                                // isFetching={isFetching}
                                values={[parent]}
                                elementsMapper={value => <InternItem key={value.id} {...value} />}
                            />
                        </Grid>
                        <Grid item xs={12}></Grid>

                    </Grid>
                </Container>
            </Paper>
        </Grid>}

        {type === 1 && <Grid item xs={12}>
            <Paper>
                <Container>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <Typography variant="h5" component="div">
                                Стажеры
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <ItemsList
                                emptyText={'Стажеров нет'}
                                // isFetching={isFetching}
                                values={workshifts}
                                elementsMapper={value => <InternItem key={value.id} {...value} parent_type={type} />}
                            />
                        </Grid>
                        <Grid item xs={12}></Grid>

                    </Grid>
                </Container>
            </Paper>
        </Grid>}


        <Grid item xs={12}>
            <Paper>
                <Container>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Tabs
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        value={tab}
                                        onChange={handleChange}>
                                        <Tab label="Правила" value={'rules'} />
                                        <Tab label="Карта" value={'map'} />
                                        <Tab label="Комментарии" value={'comments'} />
                                    </Tabs>
                                </Grid>

                                {restaurant?.id &&
                                    <Grid item xs={12}>
                                        {tab === 'map' && restaurant?.latitude && restaurant?.longitude && <YandexMap
                                            key={`view_r_${restaurant?.id}`}
                                            viewOnly
                                            place={[restaurant.longitude, restaurant.latitude]}
                                        />}

                                        {tab === 'comments' && <CommentsList id={restaurant?.id} />}

                                        {tab === 'rules' && <RulesList rest_id={restaurant?.id} />}

                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12}></Grid>

                    </Grid>
                </Container>
            </Paper>
        </Grid>

    </Grid>
}