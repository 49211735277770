import dayjs from 'dayjs';
import { setTimeZero } from '../../utils/utils';

export const getStatuses = (map) => Object.keys(map).reduce((a, c) => ({ ...a, [c]: map[c].status }), {})
export const getRanges = (map) => Object.keys(map).reduce((a, c) => ({ ...a, [c]: map[c].range }), {})

export const isAvailable = (dates, date, schedule) => {
    const dateKey = date.format('YYYY-MM-DD')

    const statuses = getStatuses(schedule)

    const fd = getStatuses(dates)[dateKey]
    if (!fd) {
        return statuses[date.day()] === 'work'
    } else {
        return fd === 'work'
    }
}

export const formateTimeOfDay = (day, second) => setTimeZero(dayjs()
    .set('day', day)
    .locale('ru'))
    .set('second', second)
    .format('dddd HH:mm')

export const formateSecondToHour = (second) => setTimeZero(dayjs()
    .locale('ru'))
    .set('second', second)
    .format('HH:mm')

export const createScheduleList = ({
    dates,
    schedule,
    dayCount = 7
}) => {
    const autoDates = (new Array(dayCount))
        .fill(false)
        .map((_, i) => {
            const z = setTimeZero(dayjs())
            const c = setTimeZero(z.date(z.date() + i))
            const d = c.day()
            const s = schedule[d]
            return {
                ...s,
                day: d,
                date: c,
                start: c.set('second', s.range[0]),
                end: c.set('second', s.range[1]),
            }
        })
        .reduce((a, c) => ({ ...a, [c.date.format('YYYY-MM-DD')]: c }), {})

    const summaryDates = Object.values({ ...autoDates, ...dates })
        .filter(v => v.status === 'work')
        .filter(v => v.date >= setTimeZero(dayjs()))
        .reduce((a, c) => ({ ...a, [c.date.format('YYYY-MM-DD')]: c }), {})

    const scheduleList = Object.keys(summaryDates)
        .sort((a, b) => summaryDates[a].date.unix() - summaryDates[b].date.unix()).map((key) => (summaryDates[key]))

    return scheduleList
}