import apiClient, { queryClient } from "./http-common";
import { useMutation } from "@tanstack/react-query";
import {
    useRevalidator
} from "react-router-dom";
import { errorReports } from "./errorReports";


export const useCompleteInternship = (id, { onSuccess = () => { }, onError = () => { } } = {}) => {
    const revalidator = useRevalidator();
    return useMutation({
        mutationKey: ["workshift/complete-internship"],
        mutationFn: async ({ }) => {
            return await apiClient.put("workshift/complete-internship", {}, { params: { id } });
        },
        onSuccess: async (res) => {
            await queryClient.invalidateQueries({ queryKey: ['workshift/active'] })
            await queryClient.invalidateQueries({ queryKey: ['workshift/index'] })
            await queryClient.invalidateQueries({ queryKey: [`workshift/view`, { id: `${id}` }] })
            revalidator.revalidate();
            onSuccess(res)
        },
        onError: (err) => {
            onError(err)
            errorReports(err)
        }
    })
}