import { useMutation } from "@tanstack/react-query";
import apiClient, {queryClient} from "./http-common";
import React, { useState } from 'react';
import { errorReports } from "./errorReports";

export const useSetInternGrade = (id, { onSuccess = () => { }, onError = () => { } } = {}) => {
    return useMutation({
        mutationKey: "internship-grade",
        mutationFn: async (params) => {
            if(id) {
                return await apiClient.put("internship-grade/update", params, { params: { id } }); 
            }
            return await apiClient.post("internship-grade/create", params);
        },
        onSuccess: async (res) => {
            await queryClient.invalidateQueries({ queryKey: ['internship-grade/index'] })
            await queryClient.invalidateQueries({ queryKey: [`workshift/view`] })
            onSuccess(res)
        },
        onError: (err) => {
            onError(err)
            console.log('internship-grade/create err', err)
            errorReports(err)
        },
    })
}