import React, { useEffect, forwardRef, useRef, useState } from 'react'
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { IMaskInput } from 'react-imask';

const TextMaskCustom = forwardRef((props, ref) => {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="+7-###-###-##-##"
            definitions={{
                '#': /[0-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});
export const СontactsInputs = ({ value }) => {
    return <>
        <Grid item xs={12}>
            <TextField
                label="Телефон"
                name="phone"
                id="phone"
                InputProps={{
                    inputComponent: TextMaskCustom
                }}
                fullWidth
                defaultValue={value?.phone}
            />
        </Grid>
    </>
}
