import React, { memo } from 'react'
import ToggleButton from '@mui/material/ToggleButton';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const badgeContent = {
    'not': <CloseIcon fontSize="small" color={'error'} />,
    'work': <CheckIcon fontSize="small" color={'success'} />
}
const title = {
    'not': 'Не рабочий',
    'work': 'Доступен для смены'
}

export const DayToggleButton = memo(({
    children,
    status,
    value
}) => {
    
    return <Tooltip disableFocusListener placement='top' title={title[status]}>
        <ToggleButton value={value}>
            <Badge
                overlap="rectangular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={badgeContent[status]}
            >
                {children}
            </Badge>
        </ToggleButton>
    </Tooltip>
})