import React, { useRef, useState } from 'react'
import { useDeleteWorkshiftFile } from '../../api/useDeleteWorkshiftFile';
import DeleteIcon from '@mui/icons-material/Delete';
import { BaseListAction } from '../../ui/BaseListAction/BaseListAction';


export const ReceiptActionDelete = ({
    id
}) => {
    const [isPending, setPending] = useState(false)
    const { mutate, reset, error } = useDeleteWorkshiftFile()

    return <BaseListAction
        title={'Удалить файл'}
        onConfirm={() => {
            setPending(true)
            mutate(id)
        }}
        confirmText='Удалить файл?'
        loading={isPending}
    >
        <DeleteIcon />
    </BaseListAction>
}