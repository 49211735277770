import React, { useRef, useState } from 'react'
import { ItemsList } from '../../ui/ItemsList/ItemsList';
import { ReceiptItem } from '../ReceiptItem/ReceiptItem';


export const SalesReceipt = ({ ws_id, values, ws_status }) => {

    return <ItemsList
        emptyText={'Чеки отсутствуют'}
        values={values}
        elementsMapper={({ file, ...relatation }) => <ReceiptItem
            key={relatation.id}
            file={file}
            ws_status={ws_status}
            relatation={relatation} />}
    />
}