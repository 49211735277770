import { useLocalStorage } from "@uidotdev/usehooks";

// function parseJwt(token) {
//     var base64Url = token.split('.')[1];
//     var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
//     var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
//         return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
//     }).join(''));

//     return JSON.parse(jsonPayload);
// }

export const useCurrentUser = () => {
    const [authData] = useLocalStorage('authData', null)
    // console.log('authData', authData)
    // console.log('token', parseJwt(authData.token))
    return authData?.user
}