import React, { useState } from 'react'
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import PersonIcon from '@mui/icons-material/Person';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import KeyIcon from '@mui/icons-material/Key';
import {
    Link
} from "react-router-dom";
import { useModal } from '../../utils/useModal'
import { ExitDialog } from '../../components/ExitDialog/ExitDialog'
import { useNotificationPermission } from '../../utils/firebase'
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useLocalStorage } from '@uidotdev/usehooks';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import BrightnessAutoIcon from '@mui/icons-material/BrightnessAuto';
import { useSetTitle } from '../../utils/useTitle';
import { BaseMenuListItem } from '../../ui/BaseMenuListItem/BaseMenuListItem';
import { useInstallPrompt } from '../../utils/useInstallPrompt';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import WarningIcon from '@mui/icons-material/Warning';
import ScheduleIcon from '@mui/icons-material/Schedule';
import BadgeIcon from '@mui/icons-material/Badge';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

const ExitItem = () => {
    const { handleOpen } = useModal({
        Component: ExitDialog,
        props: { foo: 'bar' }
    })
    return <BaseMenuListItem
        icon={<ExitToAppIcon />}
        listItemButtonProps={{ onClick: handleOpen }}
        listItemTextProps={{ primary: 'Выход' }}
    />
}

const NotificationItem = () => {
    const [np, getNp] = useNotificationPermission()
    if (!("Notification" in window)) {
        console.log("This browser does not support notifications.");
        return <BaseMenuListItem
            icon={<WarningIcon color={'error'} />}
            listItemButtonProps={{ color: 'error' }}
            listItemTextProps={{ primary: 'Ошибка уведомлений', secondary: 'Браузер не поддерживает уведомления' }}
        />;
    }
    return <BaseMenuListItem
        icon={np === "granted" ? <NotificationsActiveIcon /> : <NotificationsIcon />}
        listItemButtonProps={{ onClick: getNp }}
        listItemTextProps={{ primary: np === "granted" ? 'Уведомления разрешены' : 'Разрешить уведомления' }}
    />
}

const InstallAppItem = () => {
    const [allow, install] = useInstallPrompt()
    if (!allow) return null;
    return <BaseMenuListItem
        icon={<InstallMobileIcon />}
        listItemButtonProps={{ onClick: install }}
        listItemTextProps={{ primary: 'Установить приложение' }}
    />
}

const options = {
    auto: 'Автовыбор',
    dark: 'Темная',
    light: 'Светлая',
};
const plleteIcons = {
    auto: <BrightnessAutoIcon />,
    dark: <DarkModeIcon />,
    light: <LightModeIcon />,
};

const PalleteModeItem = () => {
    const [mode, setMode] = useLocalStorage('palette_mode', 'auto')//'dark' | 'light' | 'auto'
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, key) => {
        setMode(key);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return <>
        <BaseMenuListItem
            icon={plleteIcons[mode]}
            listItemButtonProps={{ onClick: handleClickListItem }}
            listItemTextProps={{ primary: 'Тема', secondary: options[mode] }}
        />
        <Menu
            id="palette_mode-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            MenuListProps={{
                'aria-labelledby': 'lock-button',
                role: 'listbox',
            }}
        >
            {Object.entries(options).map(([key, value]) => (
                <MenuItem
                    key={key}
                    value={key}
                    // disabled={index === 0}
                    selected={key === mode}
                    onClick={(event) => handleMenuItemClick(event, key)}
                >
                    {value}
                </MenuItem>
            ))}
        </Menu>
    </>
}

export const Settings = () => {

    useSetTitle("Настройки")

    return <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <nav aria-label="main">
            <List>
                <BaseMenuListItem
                    icon={<PersonIcon />}
                    listItemButtonProps={{ LinkComponent: Link, to: `/settings/user` }}
                    listItemTextProps={{ primary: 'Настройки профиля' }}
                />
                <BaseMenuListItem
                    icon={<ScheduleIcon />}
                    listItemButtonProps={{ LinkComponent: Link, to: `/schedule` }}
                    listItemTextProps={{ primary: 'График работы' }}
                />
                <BaseMenuListItem
                    icon={<BadgeIcon />}
                    listItemButtonProps={{ LinkComponent: Link, to: `/personal-files` }}
                    listItemTextProps={{ primary: 'Мои документы' }}
                />
                <BaseMenuListItem
                    icon={<HelpCenterIcon />}
                    listItemButtonProps={{ LinkComponent: Link, to: `/information-files` }}
                    listItemTextProps={{ primary: 'Правила работы' }}
                />
                <BaseMenuListItem
                    icon={<KeyIcon />}
                    listItemButtonProps={{ LinkComponent: Link, to: `/settings/password` }}
                    listItemTextProps={{ primary: 'Сменить пароль' }}
                />
                <NotificationItem />
                <InstallAppItem />
                <PalleteModeItem />
            </List>
        </nav>
        <Divider />
        <nav aria-label="secondary">
            <List>
                <ExitItem />
            </List>
        </nav>
    </Box>
}