import React, { createRef, useRef } from 'react'
import {
    // Link,
    Outlet,
    Link,
    useMatches
} from "react-router-dom";
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import SettingsIcon from '@mui/icons-material/Settings';
import ArchiveIcon from '@mui/icons-material/Archive';
import Paper from '@mui/material/Paper';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useGetBackTo, useGetTitle } from '../../utils/useTitle';
import Badge from '@mui/material/Badge';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
// import { CSSTransition, SwitchTransition, TransitionGroup } from 'react-transition-group'
// import Collapse from '@mui/material/Collapse';
// import Slide from '@mui/material/Slide';
// import Fade from '@mui/material/Fade';

export const Layout = () => {
    const ref = useRef(null);
    const [_, { pathname }] = useMatches();

    const title = useGetTitle()
    const backTo = useGetBackTo()

    React.useEffect(() => {
        ref.current.ownerDocument.body.scrollTop = 0;
    }, [pathname]);

    return (
        <Box sx={{ pb: 8 }} ref={ref}>
            <AppBar>
                <Toolbar>
                    {backTo && <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="back"
                        sx={{ mr: 2 }}
                        LinkComponent={Link}
                        to={backTo}
                    >
                        <ArrowBackIcon />
                    </IconButton>}
                    <Typography variant="h6" component="div">
                        {title}
                    </Typography>

                    {/* <Box sx={{ flexGrow: 1 }} />
                    <Box>
                        <IconButton
                            LinkComponent={Link}
                            to='/notifications'
                            size="large"
                            color="inherit"
                        >
                            <Badge badgeContent={17} color="error">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                    </Box> */}
                </Toolbar>
            </AppBar>

            {/* <Container
                sx={{ pt: 8.5 }}
            > */}

            {/* <TransitionGroup>
                <Fade key={pathname} > */}
                    <Box
                        // ref={refs[pathname]}
                        sx={{ pt: 9 }}
                    >
                        <Outlet />
                    </Box>
                {/* </Fade>
            </TransitionGroup> */}
            {/* </Container> */}

            <Paper sx={{ position: 'fixed', zIndex: 50, bottom: 0, left: 0, right: 0 }} elevation={1}>
                <BottomNavigation
                    // showLabels
                    value={pathname}
                >
                    <BottomNavigationAction LinkComponent={Link} to={'/'} value={'/'} label="Активные" icon={<RestoreIcon />} />
                    <BottomNavigationAction LinkComponent={Link} to={'/wait-result'} value={'/wait-result'} label="Отчитаться" icon={<FactCheckIcon />} />
                    <BottomNavigationAction LinkComponent={Link} to={'/all'} value={'/all'} label="Прошедшие" icon={<ArchiveIcon />} />
                    <BottomNavigationAction LinkComponent={Link} to={'/settings'} value={'/settings'} label="Настроки" icon={<SettingsIcon />} />
                </BottomNavigation>
            </Paper>
        </Box>
    );
}
