import { createContext, useContext, useState, useMemo, useEffect } from 'react';


const defaultContext = {
    setTitle: (title) => { },
    title: '',

    setBack: (to) => { },
    to: null
}

const TitleContext = createContext(defaultContext);

export const TitleProvider = (props) => {
    const [title, setTitle] = useState('')
    const [to, setBack] = useState(null)
    const context = useMemo(() => ({
        title,
        setTitle: (str) => {
            document.title = str + ' - Tequila Team Russia - Work'
            setTitle(str)
        },
        to,
        setBack: (to) => {
            setBack(to)
        }
    }), [title, setTitle, to, setBack])
    return <TitleContext.Provider {...props} value={context} />
}

export const useSetTitle = (title = '') => {
    const { setTitle } = useContext(TitleContext);
    useEffect(() => {
        setTitle(title)
        return () => setTitle('')
    }, [title])
}

export const useSetBackTo = (to) => {
    const { setBack } = useContext(TitleContext);
    useEffect(() => {
        setBack(to)
        return () => setBack(null)
    }, [to])
}
export const useGetBackTo = () => {
    const { to } = useContext(TitleContext);
    return to
}
export const useGetTitle = () => {
    const { title } = useContext(TitleContext);
    return title
}