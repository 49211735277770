import Stack from '@mui/material/Stack';
import React, { useState } from 'react'
import Typography from '@mui/material/Typography';
import ttrLogo from './ttr.webp'
import { styled } from '@mui/material/styles';

const Image = styled('img')({
    display: 'block',
    width: '100%',
    maxWidth: '300px',
    height: 'auto',
    borderRadius: '4px'
});


export const ListPlaceholder = ({ text = 'Пусто' }) => {
    return <Stack alignItems={'center'} overflow={'hidden'} spacing={3}>
        <Image
            src={ttrLogo}
            title={text}
            alt={text}
        />

        <Typography variant='h5' color={'text.secondary'}>{text}</Typography>
    </Stack>

}
