import React, { } from 'react'
import List from '@mui/material/List';

import {
    useAsyncValue
} from "react-router-dom";
import { WorkshiftListItem } from '../../ui/WorkshiftListItem/WorkshiftListItem'
import { useSetTitle } from '../../utils/useTitle';
import { ListPlaceholder } from '../../ui/ListPlaceholder/ListPlaceholder';


export const ActiveWorkshifts = () => {
    useSetTitle('Активные смены')

    const data = useAsyncValue();

    return data.data.values.length === 0 ? <ListPlaceholder text={'Смен ещё нет'} /> : <List>
        {data.data.values.map((workshift) => (<WorkshiftListItem key={workshift.id} {...workshift} />))}
    </List>
}