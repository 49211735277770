


export const FILES_URL = window.FILES_URL || ''
export const BASE_URL = window.BASE_URL || ''
export const API_BASE_URL = window.API_BASE_URL || "http://localhost/yii/yii-application/backend/web/index.php?r="

export const HEADER_ORIGIN = window.HEADER_ORIGIN || undefined

/**
 * https://developer.tech.yandex.ru/services
 */
export const YANDEX_MAP_APIKEY = window.YANDEX_MAP_APIKEY || '1c22362d-0201-4f58-8363-60fb2f4a6957'//https://developer.tech.yandex.ru/services/3
export const YANDEX_MAP_SUGGEST_APIKEY = window.YANDEX_MAP_SUGGEST_APIKEY || 'bb93d5ae-26f3-43ba-97f2-d3bae1591846'//https://developer.tech.yandex.ru/services/53


/**
 * https://console.firebase.google.com/project/test-973ae/settings/cloudmessaging
 */
export const FIREBASE_CONFIG = window.FIREBASE_CONFIG || {
    apiKey: "AIzaSyCOj10M_Ib1Lm1DHMZD2E0Sf5ulh8tbz4k",
    authDomain: "test-973ae.firebaseapp.com",
    projectId: "test-973ae",
    storageBucket: "test-973ae.appspot.com",
    messagingSenderId: "220867501191",
    appId: "1:220867501191:web:5779069ee2fdb76a71ec22",
    measurementId: "G-61L3G9H848"
};
export const FIREBASE_VAPIDKEY = window.FIREBASE_VAPIDKEY || "BGJ3uAGXP-baKJW0KRmqRKf9DJO7fjkTm0Rkv4IExBhtJXLBuk4hwJFzDBtgZtXqKSGGfM6vyNK3qajgUVzgRfY";


export const VARIABLES = {
    FILES_URL,
    BASE_URL,
    YANDEX_MAP_APIKEY,
    YANDEX_MAP_SUGGEST_APIKEY,
    API_BASE_URL,
    FIREBASE_CONFIG,
    FIREBASE_VAPIDKEY,
    HEADER_ORIGIN
}


export const PRODUCTS = {
    0: 'Другое',
    1: 'Текила',
    2: 'Ром',
    3: 'Виски',
    4: 'Jagermeister',
    5: 'Ликер',
    6: 'Настойка',
}
export const NEMO_DOT = [-16.460174, -133.395840]
