import * as React from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useExit } from '../../api/useExit'
export const ExitDialog = ({ className, handleClose, ...props }) => {
    const exit = useExit()

    const onExit = () => {
        exit()
        handleClose()
    }
    return <>
        <DialogTitle id="alert-dialog-title">
            Выход
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Вы точно хотите выйте?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} autoFocus variant='contained'>Отмена</Button>
            <Button onClick={onExit} color='error' >
                Выйти
            </Button>
        </DialogActions>
    </>
}