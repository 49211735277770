import { useQuery } from "@tanstack/react-query";
import apiClient from "./http-common";

export const useCommentsList = (rest_id, page = 0) => {
    return useQuery(
        {
            queryKey: ['rest-comments/index', { id: rest_id, page }],
            queryFn: async () => {
                return await apiClient.get(`rest-comments/index`, {
                    params: {
                        rest_id,
                        page
                        // page: query.get("page")
                    }
                });

            },
        }
    );
}