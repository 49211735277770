import apiClient, { queryClient } from "./http-common";

export const errorReports = (error) => {
    try {
        const authData = JSON.parse(localStorage.getItem("authData"));
        apiClient.post("error-reports/create", {
            error: JSON.stringify(error),
            user_id: authData?.user.id || 0
        });  
    } catch (error) {
        console.error('errorReports',error)
    }
    
}