import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import apiClient, { } from '../api/http-common'
import { useEffect, useState } from "react";
import { useCurrentUser } from './useCurrentUser'
import { VARIABLES } from "./variables";
import { errorReports } from "../api/errorReports";

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('../firebase-messaging-sw.js')
        .then(function (registration) {
            console.log('Registration successful, scope is:', registration.scope);
        }).catch(function (err) {
            console.log('Service worker registration failed, error:', err);
            errorReports(err)
        });
}

const firebaseConfig = VARIABLES.FIREBASE_CONFIG
const vapidKey = VARIABLES.FIREBASE_VAPIDKEY;

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);

export const registreUser = async (userId) => {
    try {
        const result = await getToken(messaging, { vapidKey })
        if (result) {
            await apiClient.post(`firebase-push-tokens/create`, {
                // user_id: userId,
                token: result
            });
            // Send the token to your server and update the UI if necessary
            console.log('currentToken', result)
            // ...
        } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            errorReports({error: 'No registration token available. Request permission to generate one.'})
            // ...
        }
    } catch (error) {
        console.log('An error occurred while retrieving token. ', error);
        errorReports(error)
    }
}

export const useNotificationPermission = () => {
    // "denied"|"default"|"granted"
    const [permission, setPermission] = useState('default')
    const user = useCurrentUser()
    useEffect(() => {
        if ("Notification" in window) {
            setPermission(Notification.permission)
        }
    }, [])

    const getPermission = async () => {
        if (!("Notification" in window)) {
            console.log("This browser does not support notifications.");
            errorReports({error: "This browser does not support notifications."})
            return;
        }
        try {
            const result = await Notification.requestPermission()
            if (result === "granted") {
                registreUser(user.id);
            }
            setPermission(result)
        } catch (error) {
            console.log('An error get permission. ', error);
            errorReports(error)
        }
    }

    return [permission, getPermission]
}
