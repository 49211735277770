import React, { useEffect, useRef, useState } from 'react'
import { useAcceptWorkshift } from '../../api/useAcceptWorkshift';
import { useSnackbar } from 'notistack';
import { BaseActionButton } from '../../ui/BaseActionButton/BaseActionButton';

export const AcceptButtonWorkshift = ({ ws_id }) => {
    const [isPending, setPending] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const { mutate, reset, error } = useAcceptWorkshift(ws_id, {
        onSuccess: () => enqueueSnackbar('Смена принята', { variant: 'success' }),
        onError: () => setPending(false)
    })
    return <BaseActionButton
        error={error}
        isPending={isPending}
        onClick={() => {
            setPending(true)
            mutate({})
        }}
        reset={reset}
    >Подтвердить</BaseActionButton>
}