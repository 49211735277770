import React, { useState } from 'react'
import {
    useAsyncValue
} from "react-router-dom";
import { useSetTitle } from '../../utils/useTitle';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import LoadingButton from '@mui/lab/LoadingButton';
import { ScheduleList } from './ScheduleList';
import { mapParticularsToServer, mapPeriodicitiesToServer } from './mappers';
import { useSchedule } from './useSchedule';
import { Periodicitie } from './Periodicitie';
import { Particulars } from './Particulars';
import { useSendSchedule } from '../../api/useSendSchedule';
import { useSnackbar } from 'notistack';

export const Schedule = () => {
    useSetTitle('График работы')
    const { data } = useAsyncValue();

    const [tab, setTab] = useState(0);

    const { enqueueSnackbar } = useSnackbar();
    const { mutate, isPending } = useSendSchedule({
        onSuccess: () => {
            enqueueSnackbar('Расписание сохранено', { variant: 'success' })
        }
    })

    const handleTab = (event, newValue) => {
        setTab(newValue);
    };
    const {
        dates,
        isChanged,
        schedule,
        setDates,
        setSchedule
    } = useSchedule(data)

    return <Container
        component={'form'}
        action="#"
        method="POST"
        onSubmit={(e) => {
            e.preventDefault()

            const particular = mapParticularsToServer(dates)
            const periodicity = mapPeriodicitiesToServer(schedule)

            mutate({ periodicity, particular })
        }}
    >
        <Grid container sx={{ pl: 2, pr: 2 }} spacing={3}>

            <Grid item xs={12}>
                <Tabs value={tab} onChange={handleTab} centered>
                    <Tab label="Дни" />
                    <Tab label="Даты" />
                    <Tab label="Список" />
                </Tabs>
            </Grid>

            {tab === 0 && <Grid item xs={12}>
                <Periodicitie
                    schedule={schedule}
                    setSchedule={setSchedule}
                />
            </Grid>}

            {tab === 1 && <Grid item xs={12}>
                <Particulars
                    dates={dates}
                    setDates={setDates}
                    schedule={schedule}
                />
            </Grid>}

            {tab === 2 && <Grid item xs={12}>
                <ScheduleList
                    dates={dates}
                    schedule={schedule}
                />
            </Grid>}

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton
                    loading={isPending}
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={!isChanged} >Сохранить</LoadingButton>
            </Grid>
            <Grid item xs={12}></Grid>
        </Grid>
    </Container>
}