import React, { useCallback, useEffect, useRef, useState } from "react";
import { YMaps, Map, Placemark, GeolocationControl, SearchControl, useYMaps } from '@pbe/react-yandex-maps';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useClickAway } from "@uidotdev/usehooks";

export const YandexMap = ({
    place,
    onLoad = () => { },
    onChange = () => { },
    viewOnly = false,
    latitudeName,
    longitudeName,
    height = '75vh'
}) => {

    const [load, setLoad] = useState(false)
    const [enabled, setEnabled] = useState(false)

    // const ymaps = useYMaps(['Map', 'geocode'])
    const ymap = useRef(null)
    // useEffect(() => {
    //     if (!ymaps) return;
    //     let myGeocoder2 = ymaps.geocode(defaultAddress, { json: true });
    //     // console.log('myGeocoder2', myGeocoder2)
    //     myGeocoder2.then(
    //         function (res) {
    //             console.log('res2', res)
    //             // map.geoObjects.add(res.geoObjects);
    //             // Выведем в консоль данные, полученные в результате геокодирования объекта.
    //             console.log(res.geoObjects.get(0).properties.get('metaDataProperty').getAll());
    //         },
    //         function (err) {
    //             // Обработка ошибки.
    //         }
    //     );
    // }, [ymaps])

    // useEffect(() => {
    //     if (place)
    //         ymap.current?.setCenter(place)
    //     // setPlace(ePlace)
    // }, [place])

    const clickCb = useCallback((e) => {
        // console.log('clickCb e', e)
        // const eMap = e.get('target')
        // console.log('clickCb eMap', eMap)
        // console.log('clickCb ymap', ymap)

        const coords = e.get('coords');

        onChange(coords)

        // if (e.get('type') === 'mousedown') {
        //     const coords = e.get('coords');

        //     onChange(coords)
        // } else if (e.get('type') === "balloonopen") {
        //     // eMap.getData()
        //     // console.log('clickCb balloonopen', eMap.balloon.getPosition())

        //     // console.log('clickCb balloonopen e', e)
        //     // console.log('clickCb balloonopen eMap', eMap)
        //     console.log('clickCb balloonopen ymap', e.originalEvent.currentTarget)
        //     console.log('clickCb e.originalEvent.currentTarget.geoObjects.get(0).geometry.getCoordinates()',
        //         e.originalEvent.currentTarget)
        //     console.log('clickCb action.getMap().geoObjects',
        //         e.originalEvent.currentTarget.action.getMap().geoObjects)
        //     // temp1.getMap().getBounds()
        //     onChange(e.originalEvent.currentTarget.action.getMap().getBounds())
        //     // onChange(e.originalEvent.currentTarget.geoObjects.get(0).geometry.getCoordinates())
        // } else {
        //     if (viewOnly) return;
        //     const coords = e.get('coords');
        //     // if (!eMap.balloon.isOpen()) {
        //     //     // var coords = e.get('coords');
        //     //     eMap.balloon.open(coords, {
        //     //         contentHeader: 'Событие!',
        //     //         contentBody: '<p>Кто-то щелкнул по карте.</p>' +
        //     //             '<p>Координаты щелчка: ' + [
        //     //                 coords[0].toPrecision(6),
        //     //                 coords[1].toPrecision(6)
        //     //             ].join(', ') + '</p>',
        //     //         contentFooter: '<sup>Щелкните еще раз</sup>'
        //     //     });
        //     // }

        //     onChange(coords)
        // }
        // console.log('clickCb coords', coords)
        // setPlace(coords)
        // console.log('ymaps', ymaps.)
        // const myGeocoder = ymaps.geocode(coords, { json: true })
        // myGeocoder.then(
        //     function (res) {
        //         console.log('res', res)
        //         // map.geoObjects.add(res.geoObjects);
        //         // Выведем в консоль данные, полученные в результате геокодирования объекта.
        //         console.log(res.geoObjects.get(0).properties.get('metaDataProperty').getAll());
        //     },
        //     function (err) {
        //         // Обработка ошибки.
        //     }
        // );
    }, [])
    useEffect(() => {
        // console.log('ymap.current', ymap.current)
        const ymp = ymap.current
        onLoad(ymp)
        if (ymp) {
            // ymp.events.add('click', clickCb);
            // ymp.events.add('balloonopen', clickCb);
            ymp.events.add('mousedown', clickCb);
            // ymp.events.add('mouseup', clickCb);
        }
    }, [load])
    useEffect(() => {
        return () => {
            if (ymap.current) {
                // console.log('remove')
                setLoad(false)

                ymap.current.events.remove('click', clickCb)
                ymap.current = null
            }
        }
    }, [])
    const container = useClickAway((e) => { setEnabled(false) })

    return <Box ref={container} sx={{ borderRadius: '4px', overflow: 'hidden', position: "relative" }}>
        {!enabled && <Box
            onClick={() => {
                setEnabled(true)
            }}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                zIndex: 10,
                left: '-1px',
                right: '-1px',
                top: '-1px',
                bottom: '-1px',
                backgroundColor: '#000000bb'
            }}
        ><Typography>Нажмите для работы с картой</Typography></Box>}
        <input
            name={longitudeName}
            id="longitude"
            type="hidden"
            value={place[0]}
        />

        <input
            name={latitudeName}
            id="latitude"
            type="hidden"
            value={place[1]}
        />
        <Box sx={{ margin: '1px',borderRadius: '4px', overflow: 'hidden', position: "relative" }}>
            <Map
                instanceRef={ymap}
                onLoad={(api) => {
                    setLoad(true)
                }}
                width={'100%'}
                // style={{ borderRadius: '4px' }}
                height={height}
                // onClick={(event) => {
                //     const coords = event.get("coords");
                //     console.log('map click event', event)
                // }}
                // options={{

                // }}
                defaultOptions={{
                    suppressMapOpenBlock: true,
                    mapAutoFocus: true
                }}
                // state={{
                //     center: place
                // }}
                defaultState={{
                    center: place,
                    zoom: 15,
                    controls: ["zoomControl", "fullscreenControl"],
                }}
                modules={["control.ZoomControl", "control.FullscreenControl"]}
            >
                {load && <>
                    <SearchControl options={{ float: "right" }} />
                    <GeolocationControl options={{ float: "left" }} />
                    {/* <Placemark defaultGeometry={[55.751574, 37.573856]} /> */}
                    {place[0] && place[1] && <Placemark
                        options={{
                            iconLayout: "default#image",
                            iconImageSize: [50, 50],
                            iconImageHref: `https://img.icons8.com/ios-filled/2x/marker-d.png`
                        }}
                        geometry={place}
                    // defaultGeometry={place}
                    // onClick={(e) => {
                    //     // const eMap = e.get('target');
                    //     // const coords = e.get('coords');
                    //     // eMap.balloon.close()
                    //     // eMap.balloon.open(coords);
                    //     console.log('Placemark click event', e)
                    // }} 
                    />}
                </>}
            </Map>
        </Box>
    </Box>
}