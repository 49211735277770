import React, { useEffect, useRef, useState } from 'react'
import { YandexMap } from '../../components/YandexMap/YandexMap';
import { CitySelect } from '../../components/CitySelect/CitySelect';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';

export const LocationInputs = ({ value }) => {
    const [place, setPlace] = useState([-16.460174, -133.395840])
    const ymap = useRef(null)
    useEffect(() => {
        setPlace([value?.longitude, value?.latitude])
    }, [value?.longitude, value?.latitude])
    return <>
        <Grid item xs={12}>
            <FormControl fullWidth>
                <InputLabel id="restSelectCity-select-small-label">Город</InputLabel>
                <CitySelect
                    // anyVariant
                    label="Город"
                    name="city_id"
                    labelId="restSelectCity-select-small-label"
                    id="restSelectCity-select-small"
                    onChange={(v) => {
                        const pos = [v?.longitude, v?.latitude]
                        ymap.current?.setCenter(pos)
                        setPlace(pos)
                    }}
                    value={value?.city?.id}
                />
            </FormControl>
        </Grid>

        <Grid item xs={12}>
            <Typography component="span" variant="body2" color="text.secondary">Ваше место нахождения. Нужно поставить точку на карте.</Typography>
            <YandexMap
                height={'40vh'}
                latitudeName={'latitude'}
                longitudeName={'longitude'}
                key={`edit_u_${value?.id}`}
                onChange={setPlace}
                onLoad={(m) => ymap.current = m}
                place={place}
            />
        </Grid>

    </>
}