import React, { useRef, useState } from 'react'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import BalanceIcon from '@mui/icons-material/Balance';
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Stack, Typography } from '@mui/material';
import { useLocalStorage } from '@uidotdev/usehooks';
import Tooltip from '@mui/material/Tooltip';


const round1 = (value) => Math.round(value * 10) / 10
export const VolumeInput = ({
    error
}) => {
    const [variant, setVariant] = useLocalStorage('result_VolumeInput_variant', 'count')

    const [count, setCount] = useState(0);
    const [itemVolume, setItemVolume] = useState(40);
    const [volume, setVolume] = useState(0);

    const handleVariant = (event, newVariant) => {
        newVariant && setVariant(newVariant);
    };
    return <Stack spacing={1} direction={'column'}>
        <TextField
            // error={empty['volume']}
            value={volume === 0 ? undefined : volume}
            // required
            sx={{ display: 'none' }}
            type='hidden'
            name="volume"
            fullWidth
            onChange={(e) => { }}
        />
        <Stack spacing={1} direction={'row'} >

            {variant === 'volume' && <TextField
                error={error}
                value={volume}
                required
                type='number'
                label="Объем"
                inputMode='decimal'
                id="volume"
                fullWidth
                onChange={(e) => {
                    const volume = parseInt(e.target.value)
                    setVolume(volume)
                    if (itemVolume > 0) {
                        const count = volume / itemVolume
                        setCount(isNaN(count) ? 0 : round1(count))
                    }
                }}
                inputProps={{
                    min: 0
                }}
                InputProps={{
                    endAdornment: <InputAdornment position='end'>мл</InputAdornment>
                }}
            />}

            {variant === 'count' &&
                <Stack spacing={1} flexBasis={'100%'} direction={'row'}>
                    <TextField
                        error={count === 0 && error}
                        required
                        // error={empty['volume']}
                        type='number'
                        // type='text'
                        inputMode='decimal'
                        InputLabelProps={{ shrink: true }}
                        // step={0.01}
                        label="Кол-во рюмок"
                        // name="volume"
                        // id="volume"
                        fullWidth
                        inputProps={{
                            min: 0,
                            step: 0.01,
                            // pattern: "[0-9]+([\.,][0-9]+)?"//"[0-9]{10}"
                        }}
                        value={count}
                        onChange={(e) => {
                            let count = parseFloat(e.target.value)
                            count = isNaN(count) ? undefined : count
                            setCount(count)
                            const sum = (count || 0) * itemVolume
                            setVolume(isNaN(sum) ? 0 : round1(sum))
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>шт</InputAdornment>
                        }}
                    />

                    <TextField
                        error={itemVolume === 0 && error}
                        required
                        InputLabelProps={{ shrink: true }}
                        // error={empty['volume']}
                        type='number'
                        inputMode='decimal'
                        label="Объем рюмки"
                        // name="volume"
                        // id="volume"
                        value={itemVolume}
                        onChange={(e) => {
                            const volume = parseInt(e.target.value)
                            setItemVolume(volume)
                            const sum = volume * count
                            setVolume(isNaN(sum) ? 0 : sum)
                        }}
                        inputProps={{
                            min: 0
                        }}
                        fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>мл</InputAdornment>
                        }}
                    />
                </Stack>

            }

            <ToggleButtonGroup
                value={variant}
                exclusive
                onChange={handleVariant}
            >
                <Tooltip disableFocusListener placement='top' title={'Заполнить по рюмкам'}>
                    <ToggleButton value="count" aria-label="left aligned">
                        <LocalDrinkIcon />
                    </ToggleButton>
                </Tooltip>
                <Tooltip disableFocusListener placement='top' title={'Заполнить по объёму'}>
                    <ToggleButton value="volume" aria-label="centered">
                        <BalanceIcon />
                    </ToggleButton>
                </Tooltip>
            </ToggleButtonGroup>
        </Stack>
        {variant === 'count' && <Typography color="text.secondary" >Суммарный объем {isNaN(volume) ? 0 : volume} мл</Typography>}
    </Stack>
}