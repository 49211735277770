import React, { useEffect, useRef, useState } from 'react'
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { formateDateToServer } from '../../utils/utils';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';

export const BirthdateInputs = ({ value }) => {
    const [birthdate, setBirthdate] = useState(dayjs.utc(value?.birth_date).local())
    return <>
        <Grid item xs={12}>
            <TextField
                sx={{ display: 'none' }}
                type='hidden'
                name="birth_date"
                id="birth_date"
                fullWidth
                value={formateDateToServer(birthdate)}
                onChange={() => { }}
            />
            <FormControl fullWidth>
                <DatePicker
                    label="Дата рождения"
                    value={birthdate || dayjs.utc(value?.birth_date).local()}
                    onChange={(newValue) => setBirthdate(newValue)}
                />
            </FormControl>
        </Grid>
    </>
}
