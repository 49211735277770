import React, { } from 'react'
import List from '@mui/material/List';
import {
    useAsyncValue
} from "react-router-dom";
import { WorkshiftListItem } from '../../ui/WorkshiftListItem/WorkshiftListItem'
import { useSetTitle } from '../../utils/useTitle';
import { ListPlaceholder } from '../../ui/ListPlaceholder/ListPlaceholder';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';


const StoryItem = ({ }) => {
    return <Stack spacing={1} alignItems={'center'} direction={'column'}>
        <Avatar
        />
        <Typography variant="body1" color="text.primary">
            story name
        </Typography>
    </Stack>
}

export const WaitResultWorkshifts = () => {
    useSetTitle('Ожидают результат')

    const data = useAsyncValue();

    return <Stack spacing={1} direction={'column'} divider={<Divider />}>
        {/* <Stack spacing={1} direction={'row'}>
            <StoryItem />
        </Stack> */}

        {data.data.values.length === 0 ? <ListPlaceholder text={'Смен ещё нет'} /> : <List>
            {data.data.values.map((workshift) => (<WorkshiftListItem key={workshift.id} {...workshift} />))}
        </List>}
    </Stack>
}