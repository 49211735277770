import { ItemsList } from '../../ui/ItemsList/ItemsList';
import { FileItem } from '../../ui/FileItem/FileItem';
import React, { useRef, useState } from 'react'
import { useFilesByRest } from '../../api/useFilesByRest';

export const RulesList = ({ rest_id }) => {
    const [page, setPage] = useState(1)
    const { values, meta, isFetching } = useFilesByRest(rest_id, 'rules', page)

    const { pagination } = meta || {}

    const pageCount = Math.ceil((pagination?.totalCount || 0) / (pagination?.defaultPageSize || 1))


    return <ItemsList
        emptyText={'Правила отсутствуют'}
        isFetching={isFetching}
        page={page}
        pageCount={pageCount}
        setPage={setPage}
        values={values}
        elementsMapper={({ file, relatation }) => <FileItem key={relatation.id} {...file} rel_id={relatation.id} />}
    />
}