import React, { useEffect, useRef, useState } from 'react'
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ResultsList } from '../ResultsList/ResultsList';
import { ResultsForm } from '../ResultsForm/ResultsForm';
import { CommissionReportForm } from '../CommissionReportForm/CommissionReportForm';
import { isAllowSendResultsStatus } from '../../utils/STATUSES';
import { SalesReceipt } from '../SalesReceipt/SalesReceipt';
import { SalesReceiptForm } from '../SalesReceiptForm/SalesReceiptForm';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles'
import receipt_example from './receipt_example.png'
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { calculateCommission } from '../../utils/workshiftUtils';

const Image = styled('img')({
    display: 'block',
    width: '100%',
    maxWidth: '100%',
    height: 'auto',
    borderRadius: '4px'
});

export const ResultsComponent = ({
    rest_id,
    ws_id,
    workshiftFiles,
    workshiftResults,
    state,
    commission,
    base_commission,
    no_sales,
    type
}) => {
    const [tab, setTab] = useState('result');
    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const isEmpty = workshiftResults.some(v => v.is_empty)

    const srNotSet = workshiftFiles.filter(v => v.type === 'sales_receipt').length === 0
    const cmNotSet = workshiftFiles.filter(v => v.type === 'commission').length === 0
    const resultNotSet = workshiftResults.length === 0

    // useEffect(() => {
    //     if (resultNotSet) {
    //         setTab('result')
    //     } else if (cmNotSet) {
    //         setTab('reports_commission')
    //     }

    // }, [srNotSet, cmNotSet, resultNotSet])

    const {
        // amount,
        finallyCommission,
        isMinimalCommission,
        // volume
    } = calculateCommission(workshiftResults, commission, base_commission)


    if (no_sales) return null

    if (srNotSet) {
        return <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6" component="div">
                    Нужно загрузить фото товарного чека из заведения
                </Typography>
            </Grid>

            <Grid item xs={9}>
                <Stack spacing={1} direction={'column'} divider={<Divider />}>
                    <Typography textAlign={'right'} variant="body1" color="text.secondary">
                        Пример чека:
                    </Typography>

                    <Typography variant="body1" color="text.secondary">
                        Если продаж произвести не удолось, то нужно прикрепить заявление
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={3}>
                <Image
                    src={receipt_example}
                />
            </Grid>

            <Grid item xs={12}>
                <SalesReceiptForm ws_id={ws_id} />
            </Grid>

            {/* <Grid item xs={12}>

            </Grid> */}
        </Grid>
    }

    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <Tabs
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                value={tab}
                onChange={handleChange}>
                <Tab label="Результаты смены" value={'result'} />
                <Tab label="Чеки из банка" value={'reports_commission'} />
                <Tab label="Чеки из заведения" value={'reports_sales'} />
            </Tabs>
        </Grid>

        {tab === 'result' && <>
            {!no_sales && <Grid item xs={12}>
                <ResultsList ws_id={ws_id} values={workshiftResults} base_commission={base_commission} commission={commission} type={type} />
            </Grid>}

            {isAllowSendResultsStatus(state) && !isEmpty && <Grid item xs={12}>
                <ResultsForm no_sales={no_sales} ws_id={ws_id} rest_id={rest_id} type={type} />
            </Grid>}

        </>}

        {tab === 'reports_commission' && <>
            {!no_sales && <Grid item xs={12}>
                <SalesReceipt values={workshiftFiles.filter(v => v.type === 'commission')} ws_status={state} ws_id={ws_id} />
            </Grid>}

            <Grid item xs={12}>
                <Typography component="span" variant="body1" color="text.secondary">
                    Комиссия:&nbsp;
                </Typography>
                <Typography component="span" variant="body1" color="text.primary">
                    {finallyCommission}&nbsp;({isMinimalCommission ? 'минимальная комиссия' : `${commission}%`})
                </Typography>
            </Grid>

            {isAllowSendResultsStatus(state) && <>
                <Grid item xs={12}>
                    <CommissionReportForm ws_id={ws_id} />
                </Grid>
            </>}
        </>}
        {tab === 'reports_sales' && <>
            {!no_sales && <Grid item xs={12}>
                <SalesReceipt values={workshiftFiles.filter(v => v.type === 'sales_receipt')} ws_status={state} ws_id={ws_id} />
            </Grid>}

            {isAllowSendResultsStatus(state) && <>
                <Grid item xs={12}>
                    <SalesReceiptForm ws_id={ws_id} />
                </Grid>
            </>}
        </>}
    </Grid>
}