import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import { RequestErrorMessage } from '../../ui/RequestErrorMessage/RequestErrorMessage';
import {
    useNavigate
} from "react-router-dom";
import { useAsyncValue } from 'react-router-dom'
import { useSetTitle } from '../../utils/useTitle';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { useUpdateUser } from '../../api/useUpdateUser';
import { AvatarInputs } from '../../components/ProfileForm/AvatarInputs';
import { LocationInputs } from '../../components/ProfileForm/LocationInputs';
import { BirthdateInputs } from '../../components/ProfileForm/BirthdateInputs';
import { СontactsInputs } from '../../components/ProfileForm/СontactsInputs';
import { NameInputs } from '../../components/ProfileForm/NameInputs';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useNotificationPermission } from '../../utils/firebase';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useInstallPrompt } from '../../utils/useInstallPrompt';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import { styled, useTheme } from '@mui/material/styles';
import ttr_logo from './ttr.webp'
import { useSnackbar } from 'notistack';

const NotificationBtn = () => {
    const [np, getNp] = useNotificationPermission()
    return <Grid item xs={12}>
        <Box><Typography>Рекомендуем включить уведомления, что бы не пропускать новую информацию</Typography></Box>
        <Button
            icon={np === "granted" ? <NotificationsActiveIcon /> : <NotificationsIcon />}
            onClick={getNp}
            variant='contained'
        >{np === "granted" ? 'Уведомления разрешены' : 'Разрешить уведомления'}</Button>
    </Grid>
}

const InstallBtn = () => {
    const [allow, install] = useInstallPrompt()
    if (!allow) return null
    return <Grid item xs={12}>
        <Box><Typography>Установка приложения на устройство поможет быстрее получать к нему доступ и не потерять(оно совсем маленькое и почти не занимает места)</Typography></Box>
        <Button
            icon={<InstallMobileIcon />}
            onClick={install}
            variant='contained'
        >{'Установить приложение'}</Button>
    </Grid>
}

const ActionButtons = () => {
    return <>
        <NotificationBtn />
        <InstallBtn />
    </>
}
const LogoImg = styled('img')(({ theme }) => ({
    display: 'block',
    width: '100%',
    maxWidth: '100%',
    height: 'auto'
}))

const HelloScreen = () => {
    // const theme = useTheme()
    return <Grid item xs={12}>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <LogoImg
                    src={ttr_logo}
                    alt={'Tequila Team Russia'}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography>Аккаунт создан. Но мы хотим познакомиться с Вами получше. Нажимайте "Дальше" и заполните небольшую анкету</Typography>
            </Grid>
        </Grid>
    </Grid>
}

const steps = [
    {
        label: 'Привет!',
        Component: HelloScreen,
    },
    {
        form: true,
        label: 'Как тебя зовут?',
        Component: NameInputs,
    },
    {
        form: true,
        label: 'День рождения',
        Component: BirthdateInputs,
    },
    {
        form: true,
        label: 'Твой телефон',
        Component: СontactsInputs,
    },
    {
        form: true,
        label: 'Твоё фото',
        Component: AvatarInputs,
    },
    {
        form: true,
        label: 'Где ты живешь?',
        Component: (props) => <>
            <Grid item xs={12}>
                <Typography>Информация о твоем месте проживания поможет нам подбирать для тебя заведения.</Typography>
            </Grid>
            <LocationInputs {...props} />
        </>,
    },
    {
        label: 'Ещё не много',
        Component: ActionButtons,
    },
    {
        label: 'Готово',
        Component: () => <Grid item xs={12}><Typography>Осталось дождаться активации аккаунта менеджером. Вы получите уведомдление и Вам станут доступны все функции приложения.</Typography></Grid>,
    },
];
export const Welcome = () => {

    const navigate = useNavigate()

    const { enqueueSnackbar } = useSnackbar();

    const data = useAsyncValue();
    // const data = useLoaderData();
    const value = data?.data

    const [activeStep, setActiveStep] = useLocalStorage('welcome_step', 0);
    const maxSteps = steps.length;

    const currentItem = steps[activeStep]

    useSetTitle(currentItem.label)

    useEffect(() => {
        if (value?.status !== 9) {
            navigate('/')
        }
    }, [value?.status])


    const handleNext = (e) => {
        if (!currentItem.form) {
            e.preventDefault()
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const { mutate, isPending, error, reset } = useUpdateUser({
        onSuccess: () => {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            enqueueSnackbar('Данные сохранены', { variant: 'success' })
        }
    })

    const Component = currentItem.Component

    return (
        <Box
            component={'form'}
            action="#"
            method="POST"
            onSubmit={(e) => {
                e.preventDefault()
                const data = new FormData(e.currentTarget);
                const v = {
                    ...[...data.entries()].reduce((p, [k, v]) => ({ ...p, [k]: v }), {}),
                }
                // console.log('data', [...data.entries()], value)
                mutate(v)
            }}
            sx={{ pb: 7 }} >
            <AppBar>
                <Toolbar>
                    <Typography variant="h6" component="div">
                        {currentItem.label}
                        {/* Tequila Team Russia */}
                    </Typography>
                </Toolbar>
            </AppBar>

            <Container
                sx={{ pt: 9 }}>
                <Grid container spacing={3}>
                    <Component value={value} />

                    {error && <Grid item xs={12}>
                        <RequestErrorMessage
                            error={error}
                            reset={reset}
                        />
                    </Grid>}

                </Grid>
            </Container>

            <Paper sx={{ position: 'fixed', zIndex: 50, bottom: 0, left: 0, right: 0 }} elevation={3}>
                <MobileStepper
                    variant="text"
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <LoadingButton
                            type={"submit"}
                            size="small"
                            loading={isPending}
                            onClick={handleNext}
                            disabled={activeStep === maxSteps - 1}
                        >
                            Дальше
                            <KeyboardArrowRight />
                        </LoadingButton>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            <KeyboardArrowLeft />
                            Назад
                        </Button>
                    }
                />
            </Paper>
        </Box>
    );
}