import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import AddTaskIcon from '@mui/icons-material/AddTask';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import HelpIcon from '@mui/icons-material/Help';
import CancelIcon from '@mui/icons-material/Cancel';
import NightlifeIcon from '@mui/icons-material/Nightlife';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import TaskIcon from '@mui/icons-material/Task';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';

export const ICON = {
    0: <HelpIcon color='warning' />,
    1: <HelpIcon color='success' />,
    2: <CancelIcon color='error' />,
    3: <NightlifeIcon color='info' />,
    4: <NightlifeIcon color='info' />,
    5: <WhereToVoteIcon color='success' />,
    6: <AccessTimeIcon color='warning' />,
    7: <PendingActionsIcon color='info' />,
    8: <TaskIcon color='success' />,
    9: <ContentPasteOffIcon color='error' />,
    10: <HourglassTopIcon color='info' />,
    11: <CancelIcon color='error' />,
}


export const TEXT = {
    0: "Нужно подтвердить",
    1: "Подтверждена",
    2: "Отменена",
    3: "Можно завершить",
    4: "Выполняется",
    5: "Можно приступать",
    6: "Ожидается результат",
    7: "Ожидайте проверки результата",
    8: "Результат принят",
    9: "Результат отклонён",
    10: "Нужно подтвердить начало работы",
    11: "Прогул",

    999: "ошибка"
}


export const SEVERITY = {
    0: 'warning',
    1: 'success',
    2: 'error',
    3: 'info',
    4: 'info',
    5: 'success',
    6: 'warning',
    7: 'info',
    8: 'success',
    9: 'error',
    10: 'info',
    11: 'error',
}

export const COLOR = {
    0: 'warning',
    1: 'success',
    2: 'error',
    3: 'info',
    4: 'info',
    5: 'success',
    6: 'warning',
    7: 'info',
    8: 'success',
    9: 'error',
    10: 'info',
    11: 'error',
}

export const isNeedAcceptStatus = (status) => {
    switch (status) {
        case 0:
            return true

        default:
            return false
    }
}
export const isAllowStartStatus = (status) => {
    switch (status) {
        case 5:
        case 10:
            return true

        default:
            return false
    }
}
export const isAllowComleteStatus = (status) => {
    switch (status) {
        case 3:
            return true

        default:
            return false
    }
}

export const isAllowShowResultsStatus = (status) => {
    switch (status) {
        case 6:
        case 7:
        case 8:
        case 9:
            return true

        default:
            return false
    }
}
export const isAllowSendResultsStatus = (status) => {
    switch (status) {
        case 3:
        case 6:
        case 7:
        case 9:
            return true

        default:
            return false
    }
}

export const REPORT_ICON = {
    0: <HourglassTopIcon color='info' />,
    1: <AddTaskIcon color='success' />,
    2: <DoDisturbOnIcon color='error' />,
}
export const REPORT_TEXT = {
    0: "Ожидает проверки",
    1: "Подтвержден",
    2: "Отклонен",
}

export const isAllowDeleteReport = (status) => {
    switch (status) {
        case 0:
        case 2:
            return true

        default:
            return false
    }
}

export const STATUSES = { ICON, TEXT, SEVERITY, COLOR, REPORT_ICON, REPORT_TEXT }

export default STATUSES