import { useMutation } from "@tanstack/react-query";
import apiClient from "./http-common";
import React, { useState } from 'react';
import { errorReports } from "./errorReports";

export const useSingup = ({ onSuccess = () => { }, onError = () => { } } = {}) => {
    const [credentials, setCredentials] = useState(null)
    return useMutation({
        mutationKey: "auth/sing-up",
        mutationFn: async ({
            username,
            password,
            email
        }) => {
            setCredentials({
                username,
                password,
                email
            })
            return await apiClient.post("auth/sing-up", {
                "SignupForm": {
                    username,
                    password,
                    email
                }
            });
        },
        onSuccess: (res) => {
            onSuccess(credentials, res)
        },
        onError: (err) => {
            onError(credentials, err)
            console.log('auth/sing-up err', err)
            errorReports(err)
        },
    })
}