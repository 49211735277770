import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MuiLink from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {
    Link
} from "react-router-dom";
import { useSingin } from '../../api/useSingin'
import { useSingup } from '../../api/useSingup'
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import { useSetTitle } from '../../utils/useTitle';

export const SingUp = ({ className, ...props }) => {
    useSetTitle('Регистрация')

    const [wrongPassword, setWrongPassword] = useState(false)
    const [isError, setError] = useState(false)

    const { mutate: singin, isPending: isPendingSingin } = useSingin({ to: '/settings/user' })

    const { mutate, isPending: isPendingSingup } = useSingup({
        onSuccess: (credentials, res) => {
            if (res.data.error) {
                console.log(res.data)
                setError(true)
                return;
            }
            const { username, password } = credentials
            singin({
                username,
                password,
                rememberMe: "1"
            })
        },
        onError: () => setError(true)
    })

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(false)
        const data = new FormData(event.currentTarget);
        const value = [...data.entries()].reduce((p, [k, v]) => ({ ...p, [k]: v }), {})
        // console.log('data', [...data.entries()], value)
        if (value.password_confirm === value.password) {
            mutate(value)
        } else {
            setWrongPassword(true)
        }
    };

    return <Container component="main" maxWidth="xs">
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Регистрация
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                {isError && <Alert severity="error">This is an error Alert.</Alert>}

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Логин"
                    name="username"
                    // autoComplete="username"
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                // autoComplete="username"
                // autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Пароль"
                    type="password"
                    id="password"
                // autoComplete="current-password"
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password_confirm"
                    label="Ещё раз пароль"
                    type="password"
                    id="password_confirm"
                // autoComplete="current-password"
                />

                <LoadingButton
                    type="submit"
                    fullWidth
                    loading={isPendingSingup || isPendingSingin}
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Регистрация
                </LoadingButton>

                <Grid container>
                    <Grid item xs>
                        {/* <MuiLink component={Link} to="/forgot" variant="body2">
                            Забыли пароль?
                        </MuiLink> */}
                    </Grid>
                    <Grid item>
                        <MuiLink component={Link} to="/login" variant="body2">
                            Вход
                        </MuiLink>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    </Container>
}