import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MuiLink from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {
    Link
} from "react-router-dom";
import { useSingin } from '../../api/useSingin'
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import { useSetTitle } from '../../utils/useTitle';

export const Login = ({ className, ...props }) => {
    useSetTitle('Вход')
    const [isError, setError] = useState(false)
    const { mutate, isPending } = useSingin({
        onSuccess: ({ data }) => {
            if (data.error) {
                setError(true)
            }
        },
        onError: () => {
            setError(true)
        }
    })


    const handleSubmit = (event) => {
        event.preventDefault();
        setError(false)
        const data = new FormData(event.currentTarget);
        const value = [...data.entries()].reduce((p, [k, v]) => ({ ...p, [k]: v }), {})
        mutate(value)
    };

    return <Container component="main" maxWidth="xs">
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Вход
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                {isError && <Alert severity="error">Наверное вы ошиблись.</Alert>}

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Логин"
                    name="username"
                    autoComplete="username"
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Пароль"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                />
                <FormControlLabel
                    control={<Checkbox
                        value="1"
                        name="rememberMe"
                        defaultChecked
                        id="rememberMe"
                        color="primary" />}
                    label="Запомнить меня"
                />
                <LoadingButton
                    type="submit"
                    fullWidth
                    loading={isPending}
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Войти
                </LoadingButton>
                <Grid container>
                    <Grid item xs>
                        {/* <MuiLink component={Link} to="/forgot" variant="body2">
                            Забыли пароль?
                        </MuiLink> */}
                    </Grid>
                    <Grid item>
                        <MuiLink component={Link} to="/singup" variant="body2">
                            Регистрация
                        </MuiLink>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
    </Container>
}