import { useMutation } from "@tanstack/react-query";
import apiClient, { queryClient } from "./http-common";
import React, { useState } from 'react';
import { errorReports } from "./errorReports";
import { useSnackbar } from 'notistack';

export const useUploadFile = ({ onSuccess = () => { }, onError = () => { } } = {}) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation({
        mutationKey: 'upload/upload',
        mutationFn: async (file) => {
            const formData = new FormData();
            formData.append("imageFile", file);
            return await apiClient.post("upload/upload", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        },
        onSuccess: (res) => {
            console.log('res', res)
            
            onSuccess(res)
        },
        onError: (err) => {
            console.error('err', err)
            enqueueSnackbar('Ошибка при отправке файла. Попробуйте ещё раз', { variant: 'error' })
            errorReports(err)
        }
    })
}