import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import { useChangePassword } from '../../api/useChangePassword'
import { RequestErrorMessage } from '../../ui/RequestErrorMessage/RequestErrorMessage';
import {
    useNavigate
} from "react-router-dom";
import { useSetTitle } from '../../utils/useTitle';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper'

export const ChangePasswordSetting = () => {
    useSetTitle('Смена пароля')

    const [notEqual, setNotEqual] = useState(false)

    const navigate = useNavigate();
    const { mutate, isPending, error, reset } = useChangePassword({
        onSuccess: () => {
            navigate('/settings')
        }
    })

    return <Container
        component={'form'}
        action="#"
        method="POST"
        sx={{ width: '100%', bgcolor: 'background.paper' }}
        onSubmit={(e) => {
            e.preventDefault()
            setNotEqual(false)
            const data = new FormData(e.currentTarget);
            const v = {
                ...[...data.entries()].reduce((p, [k, v]) => ({ ...p, [k]: v }), {}),
            }
            console.log('data', [...data.entries()], v)
            if (v.password === v.confirm_password) {
                mutate(v)
            } else {
                setNotEqual(true)
            }

            // mutate(v)
        }}
    >
        <Paper>
            <Container>
                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <TextField
                            label="Новый пароль"
                            name="password"
                            id="password"
                            type="password"
                            fullWidth
                            error={notEqual}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Ещё раз новый пароль"
                            name="confirm_password"
                            id="confirm_password"
                            type="password"
                            fullWidth
                            error={notEqual}
                        />
                    </Grid>

                    {error && <Grid item xs={12}>
                        <RequestErrorMessage
                            error={error}
                            reset={reset}
                        />
                    </Grid>}

                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <LoadingButton loading={isPending} fullWidth type="submit" variant="contained" disabled={false} >Сохранить</LoadingButton>
                    </Grid>
                    <Grid item xs={12}></Grid>
                </Grid>
            </Container>
        </Paper>
    </Container>
}