import { useMutation } from "@tanstack/react-query";
import { useLocalStorage } from "@uidotdev/usehooks";
import apiClient from "./http-common";
import {
    useNavigate
} from "react-router-dom";
import { errorReports } from "./errorReports";


export const useExit = () => {
    const navigate = useNavigate();
    const [authData, setAuthData] = useLocalStorage('authData', null)
    const { mutate } = useMutation({
        mutationKey: "auth/refresh-token",
        mutationFn: async () => {
            return await apiClient.delete("auth/refresh-token", {
                data: {
                    "refresh-token": authData['refresh-token']
                }
            });
        },
        onSuccess: (res) => {
            if (res.data.status !== 'ok') {
                console.log(res.data)
                return;
            }
            setAuthData(null)
            navigate("/login");
        },
        onError: (err) => {
            console.log('delete auth/refresh-token err', err)
            errorReports(err)
        },
    })
    return mutate
}