import React, { useRef, useState } from 'react'
import { useCommentsList } from '../../api/useCommentsList';
import { CommentSend } from '../CommentSend/CommentSend';
import { Comment } from '../../ui/Comment/Comment';
import { ItemsList } from '../../ui/ItemsList/ItemsList';

export const CommentsList = ({ id }) => {
    const [page, setPage] = useState(1)
    const { data, isFetching } = useCommentsList(id, page)

    const { values, pagination } = data?.data || { values: [] }

    const pageCount = Math.ceil((pagination?.totalCount || 0) / (pagination?.defaultPageSize || 1))

    return <ItemsList
        emptyText={'Комментариев пока ещё нету'}
        isFetching={isFetching}
        page={page}
        pageCount={pageCount}
        setPage={setPage}
        values={values}
        elementsMapper={value => <Comment key={value.id} value={value} />}
    >
        <CommentSend rest_id={id} onSended={() => setPage(pageCount)} />
    </ItemsList>
}