import React, { useCallback, useEffect, useRef, useState } from "react";
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import { errorReports } from "../../api/errorReports";
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import apiClient, { queryClient } from "../../api/http-common";
import { useMutation } from "@tanstack/react-query";
import Typography from '@mui/material/Typography';
import { useUploadFile } from "../../api/useUploadFile";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
});

export const FileUploader = ({
    onSuccess = (result) => { },
    error,
    helperText = 'Нужно выбрать файл',
    disableHelper,
    text = 'Выбрать файл',
    loading
}) => {
    const [result, setResult] = useState(null)
    const { mutate: upload, isPending } = useUploadFile({
        onSuccess: (res) => {
            setResult(res.data)
            onSuccess(res)
        }
    })
    return <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', flexWrap: 'wrap' }}>
        <VisuallyHiddenInput name="file_id" defaultValue={result?.file_id} />
        {!disableHelper && <Box>
            <Typography color={error ? 'error' : 'info'}>{result?.file.name || helperText}</Typography>
        </Box>}
        <LoadingButton color={error ? 'error' : 'primary'} fullWidth loading={isPending || loading} component="label" variant="contained" startIcon={<CloudUploadIcon />}>
            {text}
            <VisuallyHiddenInput type="file" onChange={(e) => upload(e.target.files[0])} />
        </LoadingButton>
    </Box>
}
