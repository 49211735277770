
import React, { useRef, useState } from 'react'
import Grid from '@mui/material/Grid';
import { FileUploader } from '../FileUploader/FileUploader';
import { useSnackbar } from 'notistack';
import { useAddWorkshiftFile } from '../../api/useAddWorkshiftFile';

export const SalesReceiptForm = ({ ws_id }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { mutateAsync, isPending, error, reset } = useAddWorkshiftFile(ws_id, {
        onSuccess: () => {
            enqueueSnackbar('Файл отправлен', { variant: 'success' })
        },
        onError: () => {
            enqueueSnackbar('Ошибка при отправке. Попробуйте ещё раз', { variant: 'error' })
        }
    })
    return <FileUploader
        disableHelper
        loading={isPending}
        text={'Выбрать файл'}
        onSuccess={(result) => {
            console.log(result)
            mutateAsync({
                file_id: result.data.file.id,
                type: 'sales_receipt'
            })
        }}
    />
}