import React, { } from 'react'
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import {
    Link
} from "react-router-dom";
import ListItemIcon from '@mui/material/ListItemIcon';
import STATUSES from '../../utils/STATUSES';
import { formateDateTime } from '../../utils/utils';
import { styled } from '@mui/material/styles';
import { VARIABLES } from '../../utils/variables';
import Tooltip from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import Badge from '@mui/material/Badge';
import SchoolIcon from '@mui/icons-material/School';


const ColoredTypography = styled(Typography)(
    ({ theme, color }) => `
    color: ${(() => {
            return theme.palette[color]?.main
        })()};
  `,
);

const InternshipBadge = ({ children }) => {
    return <Tooltip disableFocusListener placement='top' title={'Стажировка'}>
        <Badge
            overlap="rectangular"
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            badgeContent={
                <SchoolIcon color={'info'} />
            }
        >
            {children}
        </Badge>
    </Tooltip>
}

export const WorkshiftListItem = ({
    id,
    restaurant,
    start,
    end,
    state,
    type
}) => {

    return <ListItem
        disablePadding
        secondaryAction={<Tooltip disableFocusListener placement='top' title={STATUSES.TEXT[state]}>{STATUSES.ICON[state]}</Tooltip>}
    >
        <ListItemButton
            LinkComponent={Link}
            to={`/workshift/${id}`}
        >
            {/* <ListItemIcon>
            {STATUSES.ICON[status]}
        </ListItemIcon> */}
            <ListItemAvatar>
                {type === 0 ?
                    <Avatar alt={restaurant.name} variant='rounded' src={VARIABLES.FILES_URL + `${restaurant.avatar?.file}`} /> :
                    <InternshipBadge>
                        <Avatar alt={restaurant.name} variant='rounded' src={VARIABLES.FILES_URL + `${restaurant.avatar?.file}`} />
                    </InternshipBadge>
                }
            </ListItemAvatar>
            <ListItemText primary={restaurant.name} secondary={<>

                <Box sx={{ textTransform: 'capitalize' }}>{dayjs.utc(start).local().locale('ru').format('dddd, DD MMMM')}</Box>
                <Box>с {dayjs.utc(start).local().locale('ru').format('HH:mm')} до {dayjs.utc(end).local().locale('ru').format('HH:mm')}</Box>
                {/* <Box>{formateDateTime(start)}</Box> */}
                {/* <Box>{formateDateTime(end)}</Box> */}
                <Box>
                    <ColoredTypography color={STATUSES.COLOR[state]}>{STATUSES.TEXT[state]}</ColoredTypography>
                </Box>
            </>} />
        </ListItemButton>
    </ListItem>
}