import { mapValues } from 'lodash';
import { formateDateToServer } from '../../utils/utils';
import dayjs from 'dayjs';

export const mapParticularsFromServer = (particulars) =>
    particulars.reduce((a, { date, end_ts, end, start, start_ts, ...c }) =>
    ({
        ...a, [date]: {
            ...c,
            date: dayjs.utc(date),
            start: dayjs.utc(start),
            end: dayjs.utc(end),
            range: [start_ts, end_ts],
        }
    }), {})

export const mapPeriodicitiesFromServer = (periodicities) =>
    periodicities.reduce((a, { day, end_ts, start_ts, ...c }) =>
        ({ ...a, [day]: { ...c, day, range: [start_ts, end_ts], } }), {})


export const mapParticularsToServer = (particulars) =>
    mapValues(particulars, ({ date, end, start, range, ...params }) => ({
        ...params,
        start_ts: range[0],
        end_ts: range[1],
        date: formateDateToServer(date, true),
        end: formateDateToServer(end),
        start: formateDateToServer(start)
    }))

export const mapPeriodicitiesToServer = (periodicities) =>
    mapValues(periodicities, ({ range, ...params }) => ({
        ...params,
        start_ts: range[0],
        end_ts: range[1],
    }))