import axios from "axios";
import { QueryClient } from "@tanstack/react-query";
import { VARIABLES } from "../utils/variables";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 200000,
    },
  },
});


const apiClient = axios.create({
  baseURL: VARIABLES.API_BASE_URL,//"http://localhost/yii/yii-application/frontend/web/index.php?r=",
  headers: {
    "Content-type": "application/json",
    'Origin': VARIABLES.HEADER_ORIGIN
  }
});

const refreshTokenFn = async () => {
  const authData = JSON.parse(localStorage.getItem("authData"));

  try {
    const response = await apiClient.post("auth/refresh-token", {
      "refresh-token": authData?.['refresh-token'],
    });

    const { token } = response.data;

    if (!token) {
      localStorage.removeItem("authData");
      console.log('not auth')
      return null
    }

    localStorage.setItem("authData", JSON.stringify({ ...authData, token }));

    return token;
  } catch (error) {
    localStorage.removeItem("authData");
    throw error
  }
};


apiClient.interceptors.request.use(
  async (config) => {
    const authData = JSON.parse(localStorage.getItem("authData"));

    if (authData?.token) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${authData?.token}`,
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);


apiClient.interceptors.response.use(
  (response) => {
    //обновляет пользователя в LS
    if ('user/view-me' === response.config.url && response.data) {
      const authData = JSON.parse(localStorage.getItem("authData"));
      localStorage.setItem("authData", JSON.stringify({ ...authData, user: response.data }));
    }
    return response
  },
  async (error) => {
    const config = error?.config;
    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;
      try {
        const token = await refreshTokenFn();
        if (token) {
          config.headers = {
            ...config.headers,
            authorization: `Bearer ${token}`,
          };
          return axios(config);
        }
      } catch (error) {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export default apiClient