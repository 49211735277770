import { errorReports } from "./errorReports";
import apiClient, { queryClient } from "./http-common";
import { useMutation } from "@tanstack/react-query";

export const useChangePassword = ({ onSuccess = () => { }, onError = () => { } } = {}) => {
    return useMutation({
        mutationKey: [`user/change-password`],
        mutationFn: async (data) => {
            return await apiClient.put("user/change-password", data);
        },
        onSuccess: (res) => {
            if (res.data.error) {
                console.log(res.data)
                return;
            }
            onSuccess(res)
        },
        onError: (err) => {
            onError(err)
            console.log('user/update', err)
            errorReports(err)
        },
    })
}