import LoadingButton from '@mui/lab/LoadingButton';
import React, { useRef, useState } from 'react'
import Box from '@mui/material/Box';
import { RequestErrorMessage } from '../RequestErrorMessage/RequestErrorMessage';

export const BaseActionButton = ({
    error,
    reset,
    onClick,
    isPending,
    children
}) => {
    return <Box>
        {error && <RequestErrorMessage
            error={error}
            reset={reset}
        />}
        <LoadingButton
            onClick={onClick}
            fullWidth
            variant='contained'
            loading={isPending}>{children}</LoadingButton>
    </Box>
}