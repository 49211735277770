import { createContext, useContext, useState, useMemo, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useBlocker } from 'react-router-dom';


const DefaultComponent = ({
    handleClose
}) => {
    return <>
        <DialogTitle id="alert-dialog-title">
            {"This is default dialog body"}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Set component
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button onClick={handleClose} autoFocus>
                Agree
            </Button>
        </DialogActions>
    </>
}
const defaultContext = {
    handleOpen: () => { },
    handleClose: () => { },
    open: false,
    render: {
        Component: DefaultComponent,
        props: {}
    },
    setRender: (render) => { }
}

const ModalContext = createContext(defaultContext);

export const useModal = ({
    Component = DefaultComponent,
    props = {}
} = {}, deps = []) => {
    const context = useContext(ModalContext);

    useEffect(() => {
        context.setRender({
            Component,
            props
        })
    }, deps)

    return context
}

export const ModalProvider = ({ children, ...props }) => {
    const [open, setOpen] = useState(false);
    const [render, setRender] = useState(defaultContext.render);

    const context = useMemo(() => ({
        open,
        handleOpen: () => setOpen(true),
        handleClose: () => setOpen(false),
        render: {
            ...render,
            props: {
                ...render.props,
                handleClose: () => setOpen(false)
            }
        },
        setRender
    }), [open, setOpen, render])

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            open &&
            (currentLocation.search !== nextLocation.search || currentLocation.pathname !== nextLocation.pathname)
    );

    useEffect(() => {
        if (blocker.state === "blocked" && open) {
            setOpen(false)
            blocker.reset()
        }
    }, [blocker.state, open])

    const { Component, props: renderProps } = context.render
    return <ModalContext.Provider {...props} value={context} >
        {children}

        <Dialog
            open={open}
            fullWidth
            maxWidth={'sm'}
            onClose={context.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Component
                {...renderProps}
            />
        </Dialog>
    </ModalContext.Provider>
}


