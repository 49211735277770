import React, { useRef, useState } from 'react'
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';

export const BaseListAction = ({
    title = '',
    onClick,
    onConfirm,
    confirmText = '',
    disabled,
    children,
    TooltipProps = {},
    IconButtonProps = {},
    loading
}) => {
    const confirmFn = (e) => {
        if (window.confirm(confirmText)) {
            onConfirm(e)
        }
    }
    return <Tooltip {...TooltipProps} disableFocusListener placement='top' title={title}>
        <IconButton
            {...IconButtonProps}
            disabled={loading || disabled}
            onClick={onConfirm ? confirmFn : onClick}
            edge="end">
            {loading ? <CircularProgress size={24} /> : children}
        </IconButton>
    </Tooltip>
}