import apiClient from "./http-common";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { isEqual } from "lodash";
import { useLocalStorage } from "@uidotdev/usehooks";

export const useCitiesList = (page = 0) => {
    const [cities, setCities] = useLocalStorage('cities_list', [])
    const { data, isFetching, refetch } = useQuery(
        {
            queryKey: ['cities', { page }],
            queryFn: async () => {
                return await apiClient.get(`cities/index`, {
                    params: {
                        page
                        // page: query.get("page")
                    }
                });

            },
            enabled: false
        }
    );

    useEffect(() => {
        if (data?.data && !isEqual(data?.data, cities)) {
            setCities(data?.data)
        }
        if (!data?.data && !cities?.length) {
            refetch()
        }
    }, [data?.data, cities])
    const values = data?.data || cities
    return { values, isFetching, refetch }
}