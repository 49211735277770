import { red } from '@mui/material/colors';
// import { createTheme } from '@mui/material/styles';
// import { ruRU } from '@mui/x-date-pickers/locales';
// // A custom theme for this app
// const theme = createTheme({
//   palette: {
//     // mode: 'dark',
//     primary: {
//       main: '#556cd6',
//     },
//     secondary: {
//       main: '#19857b',
//     },
//     error: {
//       main: red.A400,
//     },
//   },
// }, ruRU);

export const themeOptions = {
  palette: {
    // mode: 'dark',
    // primary: {
    //   main: '#556cd6',
    // },
    // secondary: {
    //   main: '#19857b',
    // },
    // error: {
    //   main: red.A400,
    // },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '8px',
          paddingRight: '8px'
        }
      }
      // defaultProps: {
      //   variant: 'outlined',
      // },
    },
  },
}

// export default theme;