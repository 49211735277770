import { useMutation } from "@tanstack/react-query";
import apiClient, { queryClient } from "./http-common";
import React, { useState } from 'react';
import { errorReports } from "./errorReports";

export const useUpdateUser = ({ onSuccess = () => { }, onError = () => { } } = {}) => {
    return useMutation({
        mutationKey: [`user/update-me`],
        mutationFn: async (data) => {
            return await apiClient.put("user/update-me", data);
        },
        onSuccess: (res) => {
            if (res.data.error) {
                console.log(res.data)
                return;
            }
            queryClient.invalidateQueries({ queryKey: [`user/view-me`] })
            onSuccess(res)
        },
        onError: (err) => {
            onError(err)
            console.log('user/update', err)
            errorReports(err)
        },
    })
}