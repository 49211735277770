import React, { useCallback, useMemo, useState } from 'react'
import Slider from '@mui/material/Slider';
import dayjs from 'dayjs';
import { end, minDistance, start } from './variables';
import { setTimeZero } from '../../utils/utils';


export const TimeRangeSlider = ({
    day = 5,
    orientation = 'horizontal',
    minRange = minDistance,
    value = [start + 3600, start + 7200],
    onChange = (start, end) => { },
    disabled
}) => {

    const valueText = useCallback((value) => {
        return setTimeZero(dayjs().set('day', day).locale('ru')).set('second', value).format('dddd HH:mm')
    }, [day])

    const handleChange = useCallback((
        event,
        newValue,
        activeThumb,
    ) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (newValue[1] - newValue[0] < minRange) {
            if (activeThumb === 0) {
                const clamped = Math.min(newValue[0], end - minRange);
                onChange([clamped, clamped + minRange]);
            } else {
                const clamped = Math.max(newValue[1], minRange);
                onChange([clamped - minRange, clamped]);
            }
        } else {
            onChange(newValue);
        }
    }, [minRange, onChange])

    const marks = useMemo(() => (new Array(Math.round(end / minRange) + 1)).fill(0).map((_, i) => ({
        value: i * minRange,
        label: setTimeZero(dayjs()
            .set('day', day)
            .locale('ru'))
            .set('second', Math.round(i * minRange))
            .format('HH:mm')
    })).filter((_, i) => !Boolean(i % 6)), [minRange])



    const sx = useMemo(() => {
        const roateLabels = orientation === 'horizontal' ? {
            ['.MuiSlider-markLabel']: {
                transform: 'translateX(-50%) rotate(45deg)'
            }
        } : {}

        const verticalStyles = orientation === 'vertical' ? {
            '& input[type="range"]': {
                WebkitAppearance: 'slider-vertical',
            },
            height: '300px'
        } : {}

        return {
            ...roateLabels,
            ...verticalStyles
        }
    }, [orientation])

    return <Slider
        // getAriaLabel={() => 'Temperature range'}
        disabled={disabled}
        value={value}
        min={start}
        max={end}
        disableSwap
        valueLabelDisplay="auto"
        step={minDistance}
        onChange={handleChange}
        orientation={orientation}
        marks={marks}
        valueLabelFormat={valueText}
        getAriaValueText={valueText}
        sx={sx}
    />
}