import React from "react";
import apiClient from "../../api/http-common";
import { useMutation } from "@tanstack/react-query";
import Button from '@mui/material/Button'
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ButtonBase from '@mui/material/ButtonBase';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import { errorReports } from "../../api/errorReports";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
        width: '100% !important'
    },
    '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
            opacity: 0.15,
        },
        '& .MuiImageMarked-root': {
            opacity: 0,
        },
        '& .MuiTypography-root': {
            border: '4px solid currentColor',
        },
    },
}));

const ImageSrc = styled(Avatar)({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: '100%',
    height: '100%'
});

const Image = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
}));


export const ImageUploader = ({
    src,
    alt,
    variant = 'circular',
    imgSx,
    onUpload = (data) => { }
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const { mutate: upload, isPending } = useMutation({
        mutationKey: "auth/login",
        mutationFn: async (file) => {
            var formData = new FormData();
            formData.append("imageFile", file);
            return await apiClient.post("upload/upload", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        },
        onSuccess: (res) => {
            if (res.data.error) {
                console.log(res.data)
                return;
            }
            enqueueSnackbar('Файл загружен', { variant: 'success' })
            onUpload(res.data)
        },
        onError: (err) => {
            enqueueSnackbar('Ошибка загрузки файла', { variant: 'error' })
            console.log('upload/upload err', err)
            errorReports(err)
        },
    })


    return <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', flexWrap: 'wrap' }}>

        <ImageButton
            focusRipple
            key={0}
            sx={imgSx}
        >
            <ImageSrc
                alt={alt}
                // sx={imgSx}
                variant={variant}
                src={src} />
            <ImageBackdrop className="MuiImageBackdrop-root" />
            <Image>
                <LoadingButton loading={isPending} component="label" color="inherit" variant="outlined" startIcon={<CloudUploadIcon />}
                    sx={{
                        position: 'relative',
                        p: 4,
                        pt: 2,
                        pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                    }}>
                    Загрузить фото
                    <VisuallyHiddenInput type="file" onChange={(e) => upload(e.target.files[0])} />
                </LoadingButton>

            </Image>
        </ImageButton>

    </Box >
}