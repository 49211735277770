import React, { useEffect, useRef, useState } from 'react'
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';


export const NameInputs = ({ value }) => {
    return <>
        <Grid item xs={12}>
            <TextField
                label="Фамилия"
                name="last_name"
                id="last_name"
                fullWidth
                defaultValue={value?.last_name}
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                label="Имя"
                name="first_name"
                id="first_name"
                fullWidth
                defaultValue={value?.first_name}
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                label="Отчество"
                name="middle_name"
                id="middle_name"
                fullWidth
                defaultValue={value?.middle_name}
            />
        </Grid>
    </>
}