import React, { useRef, useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { calculateCommission } from '../../utils/workshiftUtils';



export const ResultsSummary = ({ values, commission, base_commission }) => {
    const {
        amount,
        finallyCommission,
        isMinimalCommission,
        volume
    } = calculateCommission(values, commission, base_commission)
    return <Box>
        <Box>
            <Typography component="span" variant="body1" color="text.secondary">
                Пролив:&nbsp;
            </Typography>
            <Typography component="span" variant="body1" color="text.primary">
                {volume}&nbsp;мл
            </Typography>
        </Box>
        <Box>
            <Typography component="span" variant="body1" color="text.secondary">
                Сумма без скидки:&nbsp;
            </Typography>
            <Typography component="span" variant="body1" color="text.primary">
                {amount}
            </Typography>
        </Box>
        <Box>
            <Typography component="span" variant="body1" color="text.secondary">
                Комиссия:&nbsp;
            </Typography>
            <Typography component="span" variant="body1" color="text.primary">
                {finallyCommission}&nbsp;({isMinimalCommission ? 'минимальная комиссия' : `${commission}%`})
            </Typography>
        </Box>
    </Box>
}