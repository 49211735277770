import React, { useEffect, useRef, useState } from 'react'
import { ImageUploader } from '../ImageUploader/ImageUploader'
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { useUpdateUser } from '../../api/useUpdateUser';
import { VARIABLES } from '../../utils/variables';

export const AvatarInputs = ({ value }) => {
    const [fileResult, setFileResult] = useState(null)
    const { mutate, isPending, error, reset } = useUpdateUser({
    })
    return <>
        <Grid item xs={12}>
            <TextField
                sx={{ display: 'none' }}
                type='hidden'
                name="avatar"
                id="avatar"
                fullWidth
                value={fileResult?.file_id || value?.avatar?.id}
                onChange={() => { }}
            />
            <ImageUploader
                variant={'rounded'}
                imgSx={{ height: '250px' }}
                alt={value?.username}
                src={VARIABLES.FILES_URL + `${fileResult?.url || value?.avatar?.file}`}
                onUpload={(data) => {
                    setFileResult(data)
                    if (data)
                        mutate({ avatar: data.file_id })
                }}
            />
        </Grid>
    </>
}