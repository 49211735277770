import React, { useRef, useState } from 'react'
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useSendWorkshiftResult } from '../../api/useSendWorkshiftResult';
import { useCurrentUser } from '../../utils/useCurrentUser';
import { useSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { PRODUCTS } from '../../utils/variables';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { VolumeInput } from '../../ui/VolumeInput/VolumeInput';
import { RequestErrorMessage } from '../../ui/RequestErrorMessage/RequestErrorMessage';



export const ResultsForm = ({ rest_id, ws_id, type, no_sales }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [empty, setEmpty] = useState({})
    const [isEmpty, setIsEmpty] = useState(no_sales);
    const user = useCurrentUser()
    const { mutateAsync, isPending, error, reset } = useSendWorkshiftResult(rest_id, ws_id, {
        onSuccess: () => {
            enqueueSnackbar('Отчет отправлен', { variant: 'success' })
        }
    })

    const isEmptyInternship = (type === 1 && no_sales)

    return <Grid container spacing={2}
        component={'form'}
        action="#"
        method="POST"
        // onFocus={async (e) => { console.log('onFocus', e) }}
        // onBlur={async (e) => { console.log('onBlur', e) }}
        onSubmit={async (e) => {
            e.preventDefault()

            const target = e.currentTarget

            const data = new FormData(e.currentTarget);
            const dataArray = [...data.entries(), isEmptyInternship && ['is_empty', true]].filter(Boolean)
            // console.log('dataArray', dataArray)
            const emptyField = dataArray.reduce((p, [k, v]) => ({ ...p, [k]: !v }), {})
            if (!isEmpty) {
                delete emptyField.comment
            }
            // console.log('emptyField', emptyField)
            const isSeted = Object.values(emptyField).every(v => !v)
            if (!isSeted) {
                setEmpty(emptyField)
                return;
            }
            setEmpty({})
            const v = {
                ...dataArray.reduce((p, [k, v]) => ({ ...p, [k]: v }), {
                    rest_id,
                    ws_id,
                    user_id: user.id,
                    discounted_amount: 0
                }),
            }
            // console.log('emptyField', isSeted, emptyField)
            // console.log('dataArray', dataArray)
            try {

                const r = await mutateAsync(v)
                target.reset()
            } catch (error) {
                console.warn(error)
            }
        }}
    >
        {!no_sales && <Grid item xs={12}>
            <FormControlLabel control={<Switch
                checked={isEmpty}
                onChange={(e) => setIsEmpty(e.target.checked)}
                name='is_empty'
            />} label={type === 1 ? "Стажировка без продаж" : "Продаж не было"} />
        </Grid>}

        {!isEmpty && <>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="product-select-label">Напиток</InputLabel>
                    <Select
                        required
                        error={empty['product']}
                        labelId="product-select-label"
                        id="product"
                        label="Напиток"
                        name={'product'}
                    >
                        {Object.entries(PRODUCTS).map(([v, c]) => <MenuItem key={v} value={v}>{c}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <VolumeInput error={empty['volume']} />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    required
                    error={empty['amount']}
                    type='number'
                    inputMode='decimal'
                    inputProps={{
                        min: 0
                    }}
                    // label="Сумма до скидки"
                    label="Сумма за напиток"
                    name="amount"
                    id="amount"
                    fullWidth
                />
            </Grid>
        </>}

        {!isEmptyInternship && <Grid item xs={12}>
            <TextField
                required={isEmpty}
                error={empty['comment']}
                multiline
                label={isEmpty ? "Комментарий" : "Комментарий(необязательно)"}
                name="comment"
                id="comment"
                fullWidth
            />
        </Grid>}
        {error && <Grid item xs={12}>
            <RequestErrorMessage
                error={error}
                reset={reset}
            />
        </Grid>}
        <Grid item xs={12}>
            <Box sx={{ marginTop: '24px' }}>
                <LoadingButton
                    type={'submit'}
                    fullWidth
                    variant='contained'
                    loading={isPending}
                >{isEmptyInternship ? 'Завершить стажировку' : 'Отправить'}</LoadingButton>
            </Box>
        </Grid>
    </Grid>
}