import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme } from '@mui/material/styles';
import { ruRU } from '@mui/x-date-pickers/locales';
import { useLocalStorage } from "@uidotdev/usehooks";
import { useMemo } from 'react'
import { themeOptions } from '../theme';

export const usePalleteMode = () => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [paletteMode] = useLocalStorage('palette_mode', 'auto')//'dark' | 'light' | 'auto'

    return paletteMode === 'auto' ? (prefersDarkMode ? 'dark' : 'light') : paletteMode
}

export const useTheme = () => {
    const paletteMode = usePalleteMode()

    const theme = useMemo(() => {
        themeOptions.palette.mode = paletteMode
        return createTheme(themeOptions, ruRU)
    }, [paletteMode]);
    return theme
}
