import React, { useRef, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import { useDeleteWorkshiftResult } from '../../api/useDeleteWorkshiftResult';
import { BaseListAction } from '../../ui/BaseListAction/BaseListAction';
import { useSnackbar } from 'notistack';


export const DeleteReportAction = ({
    id
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isPending, setPending] = useState(false)
    const { mutate, reset, error } = useDeleteWorkshiftResult({
        onError: () => {
            setPending(false)
            enqueueSnackbar('Ошибка. Попробуйте ещё раз.', { variant: 'error' })
        }
    })

    return <BaseListAction
        title={'Удалить отчет'}
        onConfirm={() => {
            setPending(true)
            mutate(id)
        }}
        confirmText='Удалить отчет?'
        loading={isPending}
    >
        <DeleteIcon />
    </BaseListAction>
}