import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
    Link,
} from "react-router-dom";
import Divider from '@mui/material/Divider';
import { VARIABLES } from '../../utils/variables';
import { useSnackbar } from 'notistack';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import GradingIcon from '@mui/icons-material/Grading';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { useCopyToClipboard } from '@uidotdev/usehooks';
import MuiLink from '@mui/material/Link'
import Stack from '@mui/material/Stack';
import Rating from '@mui/material/Rating';
import { BaseListAction } from '../../ui/BaseListAction/BaseListAction';



export const InternItem = ({ id, user, internshipGrades, parent_type }) => {
    const { enqueueSnackbar } = useSnackbar()
    const [copiedText, copyToClipboard] = useCopyToClipboard();
    const { appearance = 0, discipline = 0, pursuit = 0, savvy = 0, sociability = 0 } = internshipGrades || {}

    const middle = (appearance + discipline + pursuit + savvy + sociability) / 5

    return <>
        <ListItem
            secondaryAction={parent_type === 1 && <BaseListAction
                title='Оценить стажера'
                IconButtonProps={{
                    LinkComponent: Link,
                    to: `/internship-grade/${id}`
                }}
            >
                <GradingIcon />
            </BaseListAction>}
        >
            <ListItemAvatar>
                <Avatar alt={user.first_name} src={VARIABLES.FILES_URL + `${user.avatar?.file}`} />
            </ListItemAvatar>
            <ListItemText
                primary={user.first_name}
                secondary={
                    <Stack spacing={1}>
                        <Box>
                            <Typography component="span" fontSize={'inherit'} variant="body1" color="text.secondary">
                                Телефон:&nbsp;
                            </Typography>
                            <Typography component="span" fontSize={'inherit'} variant="body1" color="text.primary">
                                {!!user.phone ? <MuiLink sx={{ textDecoration: '' }} onClick={() => {
                                    copyToClipboard(user.phone)
                                    enqueueSnackbar({
                                        variant: 'info',
                                        message: 'Телефон скопирован'
                                    })
                                }}>{user.phone}</MuiLink> : <>Н/Д</>}
                            </Typography>
                        </Box>

                        {parent_type === 1 && <Rating name="size-small" defaultValue={middle} readOnly size="small" />}
                    </Stack>
                }
            />
        </ListItem >
        <Divider />
    </>
}