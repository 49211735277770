import React, { useEffect, useRef, useState } from 'react'
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { Link, useAsyncValue } from 'react-router-dom'
import LoadingButton from '@mui/lab/LoadingButton';
import { RequestErrorMessage } from '../../ui/RequestErrorMessage/RequestErrorMessage';
import Typography from '@mui/material/Typography';
import { VARIABLES } from '../../utils/variables';
import { useSnackbar } from 'notistack';
import { useSetBackTo, useSetTitle } from '../../utils/useTitle';
import Rating from '@mui/material/Rating';
import Avatar from '@mui/material/Avatar';
import { useSetInternGrade } from '../../api/useSetInternGrade';
import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container';

const isAllowChangeGrade = (status) => {
    switch (status) {
        case 3:
        case 6:
            case 4:
        // case 9:
            return true

        default:
            return false
    }
}

export const InternshipGrade = () => {
    useSetTitle('Оценка стажера')


    const { enqueueSnackbar } = useSnackbar();
    const data = useAsyncValue();

    const { user, parent, id, restaurant, internshipGrades } = data?.data || {}

    useSetBackTo(`/workshift/${parent.id}`)

    const { mutate, isPending, error, reset } = useSetInternGrade(internshipGrades?.id, {
        onSuccess: () => {
            enqueueSnackbar('Оценка сохранена', { variant: 'success' })
        }
    })

    const allowChange = isAllowChangeGrade(parent.state)

    const gradeForm = <>
        <Grid item xs={12}>
            <Typography component="legend">Внешний вид</Typography>
            <Rating size="large" name="appearance" readOnly={!allowChange} defaultValue={internshipGrades?.appearance} />
        </Grid>

        <Grid item xs={12}>
            <Typography component="legend">Общительность</Typography>
            <Rating size="large" name="sociability" readOnly={!allowChange} defaultValue={internshipGrades?.sociability} />
        </Grid>

        <Grid item xs={12}>
            <Typography component="legend">Находчивость/смекалка</Typography>
            <Rating size="large" name="savvy" readOnly={!allowChange} defaultValue={internshipGrades?.savvy} />
        </Grid>

        <Grid item xs={12}>
            <Typography component="legend">Стремление к работе и заработку</Typography>
            <Rating size="large" name="pursuit" readOnly={!allowChange} defaultValue={internshipGrades?.pursuit} />
        </Grid>

        <Grid item xs={12}>
            <Typography component="legend">Дисциплина</Typography>
            <Rating size="large" name="discipline" readOnly={!allowChange} defaultValue={internshipGrades?.discipline} />
        </Grid>
    </>

    if (!allowChange) {
        return <Container sx={{ marginBottom: '24px' }}>
            <Paper>
                <Container>
                    <Grid container spacing={3}>

                        <Grid item xs='auto'>
                            <Avatar variant='rounded' sx={{ width: '100px', height: '100px' }} alt={user.displayName} src={VARIABLES.FILES_URL + `${user.avatar?.file}`} />
                        </Grid>
                        <Grid item xs={7}>
                            <Typography >{user.displayName}</Typography>
                        </Grid>

                        {gradeForm}

                        <Grid item xs={12}>
                            <TextField
                                label="Комментарий"
                                name="comment"
                                id="comment"
                                disabled
                                required
                                multiline
                                fullWidth
                                defaultValue={internshipGrades?.comment}
                            />
                        </Grid>

                        <Grid item xs={12}></Grid>

                    </Grid>
                </Container>
            </Paper >
        </Container >
    }


    return <Container
        component={'form'}
        action="#"
        method="POST"
        sx={{ marginBottom: '24px' }}
        onSubmit={(e) => {
            e.preventDefault()
            const data = new FormData(e.currentTarget);
            const v = {
                ...[...data.entries()].reduce((p, [k, v]) => ({ ...p, [k]: k !== 'comment' && !v ? 0 : v }), {}),
                intern_id: user.id,
                mentor_id: parent.user.id,
                rest_id: restaurant.id,
                ws_id: id,
            }
            // console.log('data', [...data.entries()], v)
            mutate(v)
        }}
    >
        <Paper>
            <Container>
                <Grid container spacing={3}>

                    <Grid item xs='auto'>
                        <Avatar variant='rounded' sx={{ width: '100px', height: '100px' }} alt={user.displayName} src={VARIABLES.FILES_URL + `${user.avatar?.file}`} />
                    </Grid>
                    <Grid item xs={7}>
                        <Typography >{user.displayName}</Typography>
                    </Grid>

                    {gradeForm}

                    <Grid item xs={12}>
                        <TextField
                            label="Комментарий"
                            name="comment"
                            id="comment"
                            required
                            multiline
                            fullWidth
                            defaultValue={internshipGrades?.comment}
                        />
                    </Grid>

                    {error && <Grid item xs={12}>
                        <RequestErrorMessage
                            error={error}
                            reset={reset}
                        />
                    </Grid>}

                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <LoadingButton
                            loading={isPending}
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={false} >Оценить</LoadingButton>
                    </Grid>
                    <Grid item xs={12}></Grid>
                </Grid>
            </Container>
        </Paper>
    </Container>
}