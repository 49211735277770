import React, { useRef, useState } from 'react'
import { BaseFileItem } from '../../ui/FileItem/FileItem';
import { isAllowSendResultsStatus } from '../../utils/STATUSES';
import Typography from '@mui/material/Typography';
import MuiLink from '@mui/material/Link';
import { formateDateRU } from '../../utils/utils';
import { ReceiptActionDelete } from '../ReceiptActionDelete/ReceiptActionDelete';


const TYPE_TEXT_MAP = {
    sales_receipt: 'Чек из заведения',
    commission: 'Чек о комиссии',
}

export const ReceiptItem = ({
    ws_status,
    relatation,
    file
}) => {

    return <BaseFileItem
        primary={<MuiLink download href={file.file}>{TYPE_TEXT_MAP[relatation.type]} - {formateDateRU(relatation.create_at)}</MuiLink>}
        secondary={<Typography
            variant="body2"
            fontSize={12}
        >
            {file.name}
        </Typography>}
        secondaryAction={
            isAllowSendResultsStatus(ws_status) && <ReceiptActionDelete id={relatation.id} />
        }
    />
}