import { CommentForm } from '../../ui/CommentForm/CommentForm'
import { useSendComment } from '../../api/useSendComment'
import React, { useRef, useState } from 'react'
import { useSnackbar } from 'notistack';

export const CommentSend = ({
    rest_id,
    onSended = () => { }
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const { mutateAsync, isPending, error, reset } = useSendComment({
        onSuccess: () => {
            onSended()
            enqueueSnackbar('Комменатрий отправлен', { variant: 'success' })
        }
    })

    return <CommentForm
        error={error}
        reset={reset}
        isPending={isPending}
        onSubmit={async (e) => {
            e.preventDefault()

            const target = e.currentTarget

            const data = new FormData(e.currentTarget);
            const v = {
                ...[...data.entries()].reduce((p, [k, v]) => ({ ...p, [k]: v }), { rest_id }),
            }
            try {
                const r = await mutateAsync(v)
                target.reset()
            } catch (error) {
                console.warn(error)
            }
        }}
    />
}