import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MuiLink from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useMutation } from "@tanstack/react-query";
import { useLocalStorage } from "@uidotdev/usehooks";
import apiClient from "../../api/http-common";
import {
    useNavigate,
    Link
} from "react-router-dom";
import { useSetTitle } from '../../utils/useTitle';

export const Forgot = ({ className, ...props }) => {
    useSetTitle('Востановление пароля')
    const navigate = useNavigate();
    const [authData, setAuthData] = useLocalStorage('authData', null)
    const { mutate } = useMutation({
        mutationKey: "auth/login",
        mutationFn: async ({
            username,
            password,
            rememberMe
        }) => {
            return await apiClient.post("auth/login", {
                "LoginForm": {
                    username,
                    password,
                    "rememberMe": rememberMe ? "1" : "0"
                }
            });
        },
        onSuccess: (res) => {
            if (res.data.error) {
                console.log(res.data)
                return;
            }
            setAuthData(res.data)
            navigate("/");
        },
        onError: (err) => {
            console.log('auth/login err', err)
        },
    })


    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const value = [...data.entries()].reduce((p, [k, v]) => ({ ...p, [k]: v }), {})
        // console.log('data', [...data.entries()], value)
        mutate(value)
    };

    return <Container component="main" maxWidth="xs">
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Востановлени пароля
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    // autoComplete="email"
                    autoFocus
                />

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Отправить
                </Button>
                <Grid container>
                    <Grid item xs>
                        <MuiLink component={Link} to="/login" variant="body2">
                            Вход
                        </MuiLink>
                    </Grid>
                    <Grid item>
                        <MuiLink component={Link} to="/singup" variant="body2">
                            Регистрация
                        </MuiLink>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
    </Container>
}