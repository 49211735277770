import List from '@mui/material/List';
import Pagination from '@mui/material/Pagination';
import React, { useState } from 'react'
import { Preloader } from '../Preloader/Preloader';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const ItemsList = ({
    page,
    values,
    isFetching,
    setPage,
    pageCount = 0,

    children,

    emptyText,

    elementsMapper = () => null
}) => {

    return <Box sx={{ display: 'flex', flexDirection: 'column' }}>

        {!!values.length && <List>
            {values.map(elementsMapper)}
        </List>}

        {isFetching && <Preloader />}

        {(!values.length && !isFetching && emptyText) && <Typography variant="body2" color="text.secondary">{emptyText}</Typography>}

        {pageCount > 1 && <Pagination sx={{ marginLeft: 'auto' }} page={page} count={pageCount} size="small"
            onChange={(_, page) => setPage(page)} />}

        {children}
    </Box>
}