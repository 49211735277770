import React, { } from 'react'
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { ListPlaceholder } from '../../ui/ListPlaceholder/ListPlaceholder';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { createScheduleList } from './utils';



export const ScheduleList = ({
    schedule,
    dates
}) => {

    const scheduleList = createScheduleList({
        dayCount: 50,//date.daysInMonth(),
        schedule,
        dates
    })

    return <>
        {scheduleList.length === 0 ? <ListPlaceholder text={'В этом месяце нет'} /> : <List>
            {scheduleList.map((item) => (
                <ListItem
                // disablePadding
                // secondaryAction={}
                >
                    <ListItemIcon>
                        <Typography textTransform={'uppercase'}>{item.start.local().locale('ru').format('dd')}</Typography>
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography textTransform={'capitalize'}>{item.start.local().locale('ru').format('dddd, DD MMMM')}</Typography>
                        } secondary={
                            <Typography>с {item.start.local().locale('ru').format('HH:mm')} до {item.end.local().locale('ru').format('HH:mm')}</Typography>
                        } />
                </ListItem>))}
        </List>}
    </>
}